import styled from "@emotion/styled";
import React from "react";

import { PleaseGoUseFlexInstead } from "~src/designSystem/deprecated/Flexbox";
import { Flex } from "~src/designSystem/layout/Flex";
import { t } from "~src/designSystem/theme";

import type { RadioGroupHeaderImplicitlyAddedProps } from ".";

export const RadioPrimaryText = styled.div<{ isSelected?: boolean }>`
  color: ${(props) =>
    props.isSelected === true
      ? props.theme.components.Radio.primaryTextColor
      : props.theme.components.Radio.secondaryTextColor};
  font-weight: ${(props) => props.theme.components.Radio.font.weight};
  font-size: ${(props) => props.theme.components.Radio.font.size};
  line-height: ${(props) => props.theme.components.Radio.font.lineHeight};
`;

export const RadioSecondaryText = styled.div`
  color: ${(props) => props.theme.components.Radio.secondaryTextColor};
  font-weight: ${(props) => props.theme.components.Radio.font.weight};
  font-size: ${(props) => props.theme.components.Radio.font.secondarySize};
  line-height: ${(props) => props.theme.components.Radio.font.lineHeight};
`;

export const RadioValueText = styled.div`
  color: ${(props) => props.theme.components.Radio.valueTextColor};
  display: flex;
  align-items: center;
  font-weight: ${(props) => props.theme.components.Radio.font.weight};
  font-size: ${(props) => props.theme.components.Radio.font.size};
  line-height: ${(props) => props.theme.components.Radio.font.lineHeight};
`;

const RadioGroupHeaderContainer = styled.div`
  display: flex;
  justify-content: space-between;
  flex: 1;
  color: ${(props) => props.theme.components.RadioGroup.headerTextColor};
  background-color: ${(props) => props.theme.components.RadioGroup.headerBgColor};
  padding: ${t.c.spacing("2", "5")};
  font-weight: ${(props) => props.theme.components.RadioGroup.font.weight};
  font-size: ${(props) => props.theme.components.RadioGroup.font.size};
  line-height: ${(props) => props.theme.components.RadioGroup.font.lineHeight};
`;

export interface IRadioGroupDefaultHeader {
  leftHeaderLabel: string;
  rightHeaderLabel: string;
  labelProps?: RadioGroupHeaderImplicitlyAddedProps;
}

export const RadioGroupDefaultHeader: React.FC<IRadioGroupDefaultHeader> = (props) => {
  return (
    <RadioGroupHeaderContainer {...props.labelProps}>
      <div>{props.leftHeaderLabel}</div>
      <div>{props.rightHeaderLabel}</div>
    </RadioGroupHeaderContainer>
  );
};

export interface RadioDefaultRowProps {
  isSelected?: boolean;
  primaryText: React.ReactChild;
  secondaryText?: React.ReactChild;
  valueText?: React.ReactChild;
}

export const RadioDefaultRow: React.FC<RadioDefaultRowProps> = (props) => {
  return (
    <PleaseGoUseFlexInstead grow={1} row justifyContent="space-between">
      <Flex direction="column">
        <RadioPrimaryText className="radio-primary-color-on-hover" isSelected={props.isSelected}>
          {props.primaryText}
        </RadioPrimaryText>
        <RadioSecondaryText>{props.secondaryText}</RadioSecondaryText>
      </Flex>
      <RadioValueText>{props.valueText}</RadioValueText>
    </PleaseGoUseFlexInstead>
  );
};
