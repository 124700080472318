import styled from "@emotion/styled";
import { useRouter } from "next/router";
import React from "react";

import { Text } from "~src/designSystem/atoms/Text";
import { PleaseGoUseFlexInstead } from "~src/designSystem/deprecated/Flexbox";
import { Gridbox } from "~src/designSystem/layout/Gridbox";
import { Spacer } from "~src/designSystem/layout/Spacer";
import { IconText } from "~src/designSystem/molecules/IconText";
import { Head } from "~src/designSystem/sortLater/Head";
import { ThemeProvider } from "~src/shared/darkMode/ThemeProvider";
import { TABLET_MAX } from "~src/shared/styles/responsive";

const PermissionDeniedPage: React.FC = () => {
  const router = useRouter();
  return (
    <ThemeProvider force="dark">
      <WrapperContainer gridTemplateColumns="auto auto">
        <Head title="Permission Denied | Pipe" />
        <PleaseGoUseFlexInstead justifyContent="center" alignItems="center" pad width="600px">
          <PleaseGoUseFlexInstead justifyContent="center" maxWidth="480px" pad>
            <Text color="contentEmphasis" text="titleLG">
              Uh oh...
            </Text>
            <Spacer y="4" />
            <Text color="contentSecondary" text="paragraph">
              You do not have sufficient privileges to view this page.
            </Text>
            <Spacer y="8" />
            <a onClick={() => router.back()}>
              <IconText iconName="arrow-left" iconSize={10}>
                Back
              </IconText>
            </a>
          </PleaseGoUseFlexInstead>
        </PleaseGoUseFlexInstead>
      </WrapperContainer>
    </ThemeProvider>
  );
};

export default PermissionDeniedPage;

const WrapperContainer = styled(Gridbox)`
  height: 100vh;
  background: #000000;
  @media (max-width: ${TABLET_MAX}) {
    display: flex;
    flex-direction: column-reverse;
    align-items: center;
    justify-content: center;
  }
`;
