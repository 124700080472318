import styled from "@emotion/styled";
import React, { useMemo } from "react";

import { Text } from "~src/designSystem/atoms/Text";
import { PleaseGoUseFlexInstead } from "~src/designSystem/deprecated/Flexbox";
import { LoadingSpinner } from "~src/designSystem/loading/LoadingSpinner";
import { IImageOrSVG, ImageOrSVG } from "~src/designSystem/sortLater/ImageOrSVG";
import { t } from "~src/designSystem/theme";
import { CSVLogo } from "~src/shared/dataSources/manual/CSVLogo";

import { CheckmarkIcon } from "../icons/CheckmarkIcon";
import { NeedsAttentionIcon } from "../icons/NeedsAttentionIcon";

export type IDataSourceTypes = "live" | "manual" | "requested";
export type ILiveDataSourceStatus = "syncing" | "disconnected" | "live";
export type IManualDataSourceStatus = "processing" | "processed" | "error" | "stale";
export type IRequestedDataSourceStatus = "requested";

export type IDataSourceRowProps = {
  icon?: IImageOrSVG;
  fallbackIcon?: React.ReactNode;
  label: string;
  type: IDataSourceTypes;
  status?: ILiveDataSourceStatus | IManualDataSourceStatus | IRequestedDataSourceStatus;
  actionOnClick?: () => void;
};

export const DataSourceRow: React.FC<IDataSourceRowProps> = (props) => {
  const { icon, fallbackIcon, label, type, status, actionOnClick } = props;

  const dataSourceInfo = useMemo(() => {
    switch (type) {
      case "live":
        return {
          icon:
            icon != null ? (
              <ImageOrSVG src={icon} width={24} height={24} />
            ) : (
              <FallbackIconWrapper>{fallbackIcon}</FallbackIconWrapper>
            ),
          label,
          status,
          action: status === "disconnected" ? "Reconnect" : null,
        };
      case "manual":
        return {
          icon: <ImageOrSVG src={CSVLogo} width={24} height={24} />,
          label,
          status,
          action: status === "error" || status === "stale" ? "Re-upload" : null,
        };
      case "requested":
      default:
        return {
          icon: <PlaceholderIcon />,
          label,
          status,
          action: "Add Source",
        };
    }
  }, [fallbackIcon, icon, label, status, type]);

  return (
    <PleaseGoUseFlexInstead row alignItems="center" gap="8px">
      <LabelWrapper row alignItems="center" gap="8px" grow={1}>
        {dataSourceInfo.icon}
        <Text
          css={{
            overflow: "hidden",
            whiteSpace: "nowrap",
            textOverflow: "ellipsis",
          }}
        >
          {dataSourceInfo.label}
        </Text>
      </LabelWrapper>
      <PleaseGoUseFlexInstead row alignItems="center" gap="8px">
        {dataSourceInfo.action != null && actionOnClick != null && (
          <DataSourceActionButton onClick={actionOnClick}>
            <Text text="caption">{dataSourceInfo.action}</Text>
          </DataSourceActionButton>
        )}
        <ConnectionStatusTag status={dataSourceInfo.status ?? "requested"} />
      </PleaseGoUseFlexInstead>
    </PleaseGoUseFlexInstead>
  );
};

const ConnectionStatusTag: React.FC<{
  status: ILiveDataSourceStatus | IManualDataSourceStatus | IRequestedDataSourceStatus;
}> = (props) => {
  const { status } = props;

  const statusIcon = useMemo(() => {
    switch (status) {
      case "syncing":
      case "processing":
        return <LoadingSpinner size="12px" color={t.colors.contentDefault.toString()} />;
      case "live":
      case "processed":
        return <CheckmarkIcon />;
      default:
        return <NeedsAttentionIcon />;
    }
  }, [status]);

  return (
    <ConnectionStatusWrapper>
      {statusIcon}
      <Text text="caption" color="contentSecondary">
        {status}
      </Text>
    </ConnectionStatusWrapper>
  );
};

const LabelWrapper = styled(PleaseGoUseFlexInstead)`
  overflow: hidden;
`;

const DataSourceActionButton = styled.button`
  cursor: pointer;
  span {
    color: ${t.colors.interactiveAccentDefault.toString()};
`;

const ConnectionStatusWrapper = styled(PleaseGoUseFlexInstead)`
  background: ${t.colors.surfaceRaise.toString()};
  border-radius: ${t.space[5].toString()};
  flex-direction: row;
  align-items: center;
  padding: 6px;
  height: 20px;
  gap: ${t.space[1].toString()};

  span {
    text-transform: capitalize;
  }
`;

const PlaceholderIcon = styled.div`
  border-radius: 40px;
  width: 24px;
  height: 24px;
  border: ${t.c.border("1", "dashed", "strokeDefault")};
`;

const FallbackIconWrapper = styled.div`
  width: 24px;
  height: 24px;
  & > svg {
    width: 100%;
    height: 100%;
  }
`;
