/* eslint-disable simple-import-sort/imports */
import styled from "@emotion/styled";
import { Item } from "@react-stately/collections";
import { FormikErrors, useFormik } from "formik";
import React, { useState } from "react";
import toast from "react-hot-toast";
import * as yup from "yup";

import {
  legal_entity_type_enum_enum,
  country_code_enum_enum,
} from "~src/__generated__/graphql/types";
import { Button } from "~src/designSystem/atoms/Button";
import { WarningMessage } from "~src/shared/informationals/WarningMessage";
import { Select } from "~src/designSystem/molecules/Select";
import { TextField } from "~src/designSystem/atoms/TextField";
import { StatesMap } from "~src/shared/constants";
import { callRequest } from "~src/shared/requests/useRequest";
import { ICountryCode, ILegalEntityType } from "~src/shared/types";
import {
  useFetchLegalEntity,
  ILegalEntity,
} from "~src/vendor/settingsLegalEntities/useLegalEntity";
import { ISegmentTrackEvent, useAnalytics } from "~src/shared/thirdParties/segment";
import { vendorRequests } from "../requests";
import { View } from "~src/designSystem/atoms/View";

interface IForm {
  name: string;
  tin: string;
  public_id: string;
  legalEntityType: ILegalEntityType;
  address1: string;
  address2: string;
  city: string;
  state: string;
  stateOfIncorporation: string;
  postalCode: string;
  country: ICountryCode;
}

interface IProps {
  onSubmit: () => void;
  onSuccess?: () => void;
}

const entityToForm = (entity: ILegalEntity | undefined): IForm => ({
  name: entity?.name ?? "",
  tin: entity?.tin ?? "",
  public_id: entity?.public_id ?? "",
  legalEntityType: entity?.entity_type ?? legal_entity_type_enum_enum.c_corp,
  address1: entity?.address1 ?? "",
  address2: entity?.address2 ?? "",
  city: entity?.city ?? "",
  state: entity?.state ?? "",
  stateOfIncorporation: entity?.state_of_incorporation ?? "",
  postalCode: entity?.postal_code ?? "",
  country: entity?.country ?? country_code_enum_enum.US,
});

enum FormStep {
  address,
  entityType,
}

export const LegalEntityFormHome: React.FC<IProps> = (props) => {
  const [submitError, setSubmitError] = useState<string | null>(null);
  const { trackEvent } = useAnalytics();
  const [validate, setValidate] = useState<boolean>(false);
  const [step, setStep] = useState<FormStep>(FormStep.address);
  const [loading, setLoading] = useState<boolean>(false);
  const legalEntityData = useFetchLegalEntity();

  const entity = legalEntityData.requirement?.entity;

  const initialValues: IForm = entityToForm(entity);

  const onSubmit = async (values: IForm) => {
    setLoading(true);
    const res = await callRequest(
      vendorRequests.upsertLegalEntity({
        ...values,
        state: values.state !== "" ? values.state : null,
      }),
    );
    setLoading(false);
    setSubmitError(res.error?.errorMessage ?? "");
    if (res.ok) {
      trackEvent(ISegmentTrackEvent.VendorLegalEntityFormInboxSubmitted);
      toast.success("Info successfully changed.");
      props.onSubmit();
      props.onSuccess?.();
    }
  };

  const validationSchema = yup.object({
    name: yup.string().required("Required"),
    address1: yup.string().required("Required"),
    city: yup.string().required("Required"),
    state: yup.string().required("Required"),
    legalEntityType: yup.string().required("Required"),
    postalCode: yup.string().required("Required"),
  });

  const formik = useFormik({
    initialValues,
    validationSchema,
    onSubmit,
    enableReinitialize: true,
  });

  const { values, validateForm, errors } = formik;

  const nextStep = () => {
    setValidate(true);
    validateForm().then((err: FormikErrors<IForm>) => {
      if (Object.keys(err).length > 0) {
        return;
      }
      trackEvent(ISegmentTrackEvent.VendorLegalEntityFormInboxStepClicked);
      setStep(FormStep.entityType);
    });
  };

  return (
    <form onSubmit={formik.handleSubmit}>
      <View css={{ maxWidth: "585px" }}>
        <Subheader>{step === FormStep.address ? 1 : 2}/2</Subheader>
        <Header>Verify your business</Header>
        <Subheader>Provide legal entity name and address to continue trading</Subheader>
        <EntityFormGrid>
          {step === FormStep.address && (
            <>
              <TextField
                data-private
                label="Legal Entity Name"
                name="name"
                errorMessage={validate && errors.name !== undefined ? errors.name : undefined}
                value={values.name}
                onChange={(value) => formik.setFieldValue("name", value)}
              />
              <TextField
                data-private
                label="Address 1"
                name="address1"
                errorMessage={
                  validate && errors.address1 !== undefined ? errors.address1 : undefined
                }
                value={values.address1}
                onChange={(value) => formik.setFieldValue("address1", value)}
              />
              <TextField
                data-private
                label="Address 2"
                name="address2"
                value={values.address2}
                onChange={(value) => formik.setFieldValue("address2", value)}
              />
              <CityStateRow>
                <TextField
                  data-private
                  label="City"
                  name="city"
                  errorMessage={validate && errors.city !== undefined ? errors.city : undefined}
                  value={values.city}
                  onChange={(value) => formik.setFieldValue("city", value)}
                />

                <Select
                  data-private
                  selectedKey={values.state}
                  label="State"
                  errorMessage={validate && errors.state !== undefined ? errors.state : undefined}
                  name="state"
                  onSelectionChange={(value) => formik.setFieldValue("state", value)}
                >
                  {Object.entries(StatesMap).map(([value, label]) => (
                    <Item key={value}>{label}</Item>
                  ))}
                </Select>
              </CityStateRow>
              <TextField
                data-private
                label="Zip"
                name="postalCode"
                errorMessage={
                  validate && errors.postalCode !== undefined ? errors.postalCode : undefined
                }
                value={values.postalCode}
                onChange={(value) => formik.setFieldValue("postalCode", value)}
              />
              {submitError !== null && (
                <View css={{ gridColumn: "1/-1" }}>
                  <WarningMessage type="error" message={submitError} />
                </View>
              )}
              <View>
                <Button kind="secondary" size="medium" onClick={() => nextStep()}>
                  Continue
                </Button>
              </View>
            </>
          )}
          {step === FormStep.entityType && (
            <>
              <Select
                data-private
                selectedKey={values.legalEntityType}
                label="Legal Entity Type"
                errorMessage={
                  errors.legalEntityType !== undefined ? errors.legalEntityType : undefined
                }
                name="legalEntityType"
                onSelectionChange={(value) => formik.setFieldValue("legalEntityType", value)}
              >
                {Object.entries(legal_entity_type_enum_enum).map(([value, label]) => (
                  <Item key={value}>{label}</Item>
                ))}
              </Select>
              <TextField
                data-private
                label="State of Incorporation"
                name="stateOfIncorporation"
                value={values.stateOfIncorporation}
                onChange={(value) => formik.setFieldValue("stateOfIncorporation", value)}
              />
              <TextField
                data-private
                label="Tax ID"
                name="tin"
                value={values.tin}
                onChange={(value) => formik.setFieldValue("tin", value)}
              />
              {submitError !== null && (
                <View css={{ gridColumn: "1/-1" }}>
                  <WarningMessage type="error" message={submitError} />
                </View>
              )}
              <View>
                <BackWrapper>
                  <Button kind="secondary" size="medium" onClick={() => setStep(1)}>
                    Back
                  </Button>
                </BackWrapper>
                <Button loading={loading} kind="secondary" size="medium" type="submit">
                  Submit
                </Button>
              </View>
            </>
          )}
        </EntityFormGrid>
      </View>
    </form>
  );
};

const Header = styled.h1`
  ${(props) => props.theme.textStyles.Display.Display4};
  margin-bottom: 16px;
`;

const Subheader = styled.div`
  ${(props) => props.theme.textStyles.Regular.Body300};
  color: #7d7f8c;
  margin-bottom: 16px;
`;

const CityStateRow = styled.div`
  display: grid;
  gap: 24px;
  grid-template-columns: 1fr 1fr;

  @media screen and (max-width: 1000px) {
    grid-template-columns: 1fr;
  }
`;

const BackWrapper = styled.span`
  margin-right: 12px;
`;

const EntityFormGrid = styled.div`
  display: grid;
  gap: 24px;
  grid-template-columns: 1fr;
  margin-top: 28px;

  @media screen and (max-width: 1000px) {
    grid-template-columns: 1fr;
  }
`;
