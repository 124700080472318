import { NextPage } from "next";
import React from "react";

import { VendorOnlyAuth } from "~src/shared/pageWrappers/VendorOnlyAuth";
import { Inbox } from "~src/vendor/inbox/page";

const InboxPage: NextPage = () => {
  return <Inbox />;
};

export default VendorOnlyAuth(InboxPage);
