import styled from "@emotion/styled";
import React, { useMemo, useState } from "react";
import { useDeepCompareEffect } from "react-use";
import { useDebounce } from "use-debounce";

import { PleaseGoUseFlexInstead } from "~src/designSystem/deprecated/Flexbox";
import { OldTabContainerDontUse } from "~src/designSystem/deprecated/TabContainer";
import { t } from "~src/designSystem/theme";
import { useNotNullContext } from "~src/shared/helpers/useNotNullContext";
import { callRequest } from "~src/shared/requests/useRequest";
import { vendorRequests } from "~src/vendor/requests";

import { ProposalsContext } from "../../contexts/ProposalsContext";
import { calculatePaymentSchedule, IPaymentScheduleGraphData } from "./calculatePaymentSchedule";
import { PaymentScheduleTab } from "./PaymentScheduleTab";
import { SummaryTab } from "./SummaryTab";

export const OrderBox: React.FC = () => {
  const { selectedProposals, selectedTerm } = useNotNullContext(ProposalsContext);
  const [scheduleData, setScheduleData] = useState<IPaymentScheduleGraphData | null>(null);

  const selectedProposalIDs = useMemo(
    () => selectedProposals.map((p) => p.public_id).sort(),
    [selectedProposals],
  );
  const hasSelectedProposals = selectedProposalIDs.length > 0;

  // Debounce to avoid spamming the trade.get_schedule endpoint
  const debouncedSelectedProposalIDs = useDebounce(selectedProposalIDs, 500);

  // Hoist the scheduleData so that it is kept around after a trade for PaymentScheduleTab
  useDeepCompareEffect(() => {
    (async () => {
      setScheduleData(null);
      // If we have no selected proposals, return
      if (selectedProposalIDs.length === 0) {
        return;
      }
      const res = await callRequest(
        vendorRequests.getTradeSchedule({
          revenueStreamIDs: selectedProposalIDs,
          termLength: selectedTerm.termLengthMonths,
        }),
      );
      if (!res.ok || res.data === null) {
        return;
      }
      const data = calculatePaymentSchedule(res.data);
      setScheduleData(data);
    })();
  }, [debouncedSelectedProposalIDs, selectedTerm.termLengthMonths]);

  return (
    <Wrapper>
      <OldTabContainerDontUse
        defaultIndex={0}
        headers={["Summary", "Schedule"]}
        contentComponents={[
          <SummaryTab key="summaryTab" />,
          <PaymentScheduleTab
            scheduleData={scheduleData}
            hasSelectedProposals={hasSelectedProposals}
            key="paymentScheduleTab"
          />,
        ]}
        contentPadding="0"
      />
    </Wrapper>
  );
};

export const Wrapper = styled(PleaseGoUseFlexInstead)`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;

  height: fit-content;
  width: 400px;
  min-width: 400px;

  background: ${(props) => props.theme.components.OrderBox.background};
  border-radius: ${t.radii[8].toString()};

  box-shadow: ${(props) => props.theme.components.OrderBox.boxShadow};
`;
