import styled from "@emotion/styled";
import React from "react";
import Lottie from "react-lottie-wrapper";

import { useUser } from "~src/shared/auth/useUser";
import { ErrorBoundary } from "~src/shared/errors/components/ErrorBoundary";
import { ErrorComponent } from "~src/shared/errors/components/ErrorComponent";
import { VendorLayout } from "~src/vendor/layout";

import { Approved } from "../components/Approved";
import confettiData from "../components/Approved/lottie/data/confetti.json";
import { useVendorApprovedLottieStore } from "../components/Approved/lottie/store";

export const ApprovedPage: React.FC = () => {
  const { country } = useUser().vendor;

  // Lottie confetti animation setup that can be triggered from any page.
  const isPlaying = useVendorApprovedLottieStore((s) => s.playing);
  const setIsPlaying = useVendorApprovedLottieStore((s) => s.setIsPlaying);
  const defaultOptions = {
    loop: false,
    autoplay: false,
    animationData: confettiData,
    rendererSettings: {
      preserveAspectRatio: "xMidYMid slice",
    },
  };

  return (
    <LottieWrapper
      options={defaultOptions}
      isStopped={!isPlaying}
      isPaused={false}
      isClickToPauseDisabled
      eventListeners={[
        {
          eventName: "complete",
          callback: () => {
            setIsPlaying(false);
          },
        },
      ]}
    >
      <ErrorBoundary fallback={<ErrorComponent />}>
        <VendorLayout>
          <Approved country={country ?? undefined} />
        </VendorLayout>
      </ErrorBoundary>
    </LottieWrapper>
  );
};

const LottieWrapper = styled(Lottie)`
  > svg {
    position: absolute;
    top: 0;
    left: 0;
    pointer-events: none;
  }
`;
