import styled from "@emotion/styled";
import React from "react";
import { resetIdCounter, Tab, TabList, TabPanel, Tabs } from "react-tabs";

import { t } from "~src/designSystem/theme";

interface IProps {
  // should be equal number of headers and contentComponents
  headers: string[];
  contentComponents: React.ReactNode[];
  contentPadding?: string;
  onSelect?: (index: number, lastIndex: number, event: Event) => boolean | void;
  defaultIndex?: number;
}

/**
 * @deprecated use TabLineContainer
 */
export const OldTabContainerDontUse: React.FC<IProps> = (props: IProps) => {
  const { contentPadding, defaultIndex, onSelect } = props;
  resetIdCounter();

  const renderHeaders = () => {
    return props.headers.map((header) => {
      return (
        <Tab
          key={header}
          className="react-tabs__tab_v2"
          selectedClassName="react-tabs__tab--selected_v2"
        >
          {header}
        </Tab>
      );
    });
  };

  const renderContent = () => {
    return props.contentComponents.map((contentComponent, index) => {
      return (
        <TabPanel key={props.headers[index]}>
          <PanelContainer contentPadding={contentPadding}>{contentComponent}</PanelContainer>
        </TabPanel>
      );
    });
  };

  return (
    <Wrapper>
      <Tabs defaultIndex={defaultIndex} onSelect={onSelect}>
        <TabList className="react-tabs__tab-list_v2">{renderHeaders()}</TabList>
        {renderContent()}
      </Tabs>
    </Wrapper>
  );
};

const Wrapper = styled.div`
  display: flex;
  width: 100%;
  height: 100%;

  .react-tabs {
    -webkit-tap-highlight-color: transparent;
    width: 100%;
    height: 100%;
  }

  .react-tabs__tab-list_v2 {
    display: flex;
  }

  .react-tabs__tab_v2 {
    display: flex;
    flex: 0.5;
    justify-content: center;
    align-items: center;

    position: relative;
    list-style: none;

    color: ${(props) => props.theme.components.TabContainer.text.default};
    background: ${(props) => props.theme.components.TabContainer.background.default};
    /* For left tab only */
    border-radius: 6px 0 0 0;

    padding: ${t.c.spacing("3", "5")};

    cursor: pointer;
  }

  /* For right tab only */
  .react-tabs__tab_v2:nth-of-type(2) {
    border-radius: 0 6px 0 0;
  }

  .react-tabs__tab--selected_v2 {
    color: ${(props) => props.theme.components.TabContainer.text.selected};
    font-weight: 500;
    background: ${(props) => props.theme.components.TabContainer.background.selected};
  }
`;

const PanelContainer = styled("div")<{ contentPadding?: string }>`
  padding: ${(props) => props.contentPadding ?? "35px"};
  height: 100%;
`;
