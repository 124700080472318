import React, { useEffect } from "react";

import { Gridbox } from "~src/designSystem/layout/Gridbox";
import { useAuthContext } from "~src/shared/auth/AuthProvider";
import { ISegmentTrackPage, useAnalytics } from "~src/shared/thirdParties/segment";
import { useShowLegalEntityForm } from "~src/vendor/legalEntities/hooks/useShowLegalEntityForm";
import { LegalEntityFormHomeExistingVendor } from "~src/vendor/settingsLegalEntities/LegalEntityFormHomeExisting";

import { IInboxStep, useInboxState } from "../../state";
import { TradeInfo } from "./TradeInfo";
import { TradeSelectionArea } from "./TradeSelectionArea";

export const TradeInner: React.FC = () => {
  const { trackPage } = useAnalytics();

  const wizardStep = useInboxState((state) => state.wizardStep);

  useEffect(() => {
    switch (wizardStep) {
      case IInboxStep.INBOX: {
        trackPage(ISegmentTrackPage.TradingTradePageImpression);
        break;
      }
      case IInboxStep.REVIEW: {
        trackPage(ISegmentTrackPage.TradingOrderSummaryPageImpression);
        break;
      }
      case IInboxStep.COMPLETE: {
        trackPage(ISegmentTrackPage.TradingOrderConfirmationImpression);
        break;
      }
      default: {
        break;
      }
    }
  }, [trackPage, wizardStep]);

  const { vendor } = useAuthContext();
  const { showForm, connections } = useShowLegalEntityForm();

  return (
    <>
      {showForm && vendor && (vendor.canTrade || connections !== 1) && (
        <LegalEntityFormHomeExistingVendor />
      )}
      <Gridbox gridTemplateColumns="1fr 1fr 400px" gridTemplateRows="auto 1fr" fullHeight>
        <TradeInfo />
        <TradeSelectionArea />
      </Gridbox>
    </>
  );
};
