import React from "react";

import { useUser } from "~src/shared/auth/useUser";
import { ErrorBoundary } from "~src/shared/errors/components/ErrorBoundary";
import { ErrorComponent } from "~src/shared/errors/components/ErrorComponent";
import { VendorLayout } from "~src/vendor/layout";

import { BeingReviewed } from "../components/BeingReviewed";

export const BeingReviewedPage: React.FC = () => {
  const { country } = useUser().vendor;

  return (
    <VendorLayout>
      <ErrorBoundary fallback={<ErrorComponent />}>
        <BeingReviewed country={country ?? undefined} />
      </ErrorBoundary>
    </VendorLayout>
  );
};
