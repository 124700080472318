import React from "react";

import { IAllowedRedirectPath } from "~src/shared/dataSources/allowedRedirectPaths";
import { IBillingManager } from "~src/shared/dataSources/billing/billingManagers";
import { useStepper } from "~src/shared/stepper/stepperContext";
import { IConnectDataSourceFlowSource } from "~src/shared/types";

import { ConnectApple } from "../connectors/ConnectApple";
import { ConnectChargebee } from "../connectors/ConnectChargebee";
import { ConnectChargify } from "../connectors/ConnectChargify";
import { ConnectGoCardless } from "../connectors/ConnectGoCardless";
import { ConnectPayPal } from "../connectors/ConnectPayPal";
import { ConnectRecurly } from "../connectors/ConnectRecurly";
import { ConnectStripe } from "../connectors/ConnectStripe";

interface IUseReconnectBillingArgs {
  /** Invoked after a user successfully submits a credential form. */
  onSuccess: () => Promise<void>;
  /** Invoked after a user successfully completes an OAuth flow. */
  redirectPath: IAllowedRedirectPath;
  subdomain?: string;
  vendorNumber?: string;
  source: IConnectDataSourceFlowSource;
  billingManager: IBillingManager;
}

export const useReconnectBilling = ({
  onSuccess,
  redirectPath,
  subdomain,
  vendorNumber,
  billingManager,
  source,
}: IUseReconnectBillingArgs): (() => void) => {
  const { addAndOpenStepperDialog } = useStepper();

  const connectorComponent = (() => {
    switch (billingManager.key) {
      case "stripe":
        // Note: if testing disconnections on staging, Stripe will add a new Stripe connection
        // instead of reconnecting the existing one. This is a quirk of the Stripe sandbox environment.
        // See https://pipe-financial.slack.com/archives/C02UR8PMGTG/p1646099027155339
        return <ConnectStripe redirectPath={redirectPath} />;
      case "chargebee":
        return (
          <ConnectChargebee
            subdomain={subdomain}
            redirectPath={redirectPath}
            source={source}
            onSuccess={onSuccess}
          />
        );
      case "gocardless":
        return <ConnectGoCardless redirectPath={redirectPath} />;
      case "recurly":
        return (
          <ConnectRecurly
            subdomain={subdomain}
            source={source}
            redirectPath={redirectPath}
            onSuccess={onSuccess}
          />
        );
      case "chargify":
        return (
          <ConnectChargify
            subdomain={subdomain}
            redirectPath={redirectPath}
            source={source}
            onSuccess={onSuccess}
          />
        );
      case "apple":
        return (
          <ConnectApple
            source={source}
            appleVendorNumber={vendorNumber}
            redirectPath={redirectPath}
            onSuccess={onSuccess}
          />
        );
      case "paypal":
        return <ConnectPayPal redirectPath={redirectPath} />;
      default:
        throw new Error(`Unsupported billing manager ${billingManager.key}`);
    }
  })();

  return () =>
    addAndOpenStepperDialog({
      component: connectorComponent,
      config: {
        title: "Sync your revenue",
      },
    });
};
