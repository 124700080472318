/* eslint-disable react/style-prop-object */
import { motion, MotionProps } from "framer-motion";
import React from "react";

import { t } from "~src/designSystem/theme";

export const ChevronDown: React.FC<MotionProps> = (props) => (
  <motion.svg
    width="20"
    height="20"
    viewBox="0 0 20 20"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path d="M4 7.5L10 13L16 7.5" stroke={t.colors.contentTertiary.toString()} strokeWidth="1.5" />
  </motion.svg>
);
