import create from "zustand";

type IStore = {
  playing: boolean;
  setIsPlaying: (playing: boolean) => void;
};

export const useVendorApprovedLottieStore = create<IStore>((set) => ({
  playing: false,
  setIsPlaying: (playing: boolean) => {
    set({ playing });
  },
}));
