// Code generated by go run pkg/apigen/generate. DO NOT EDIT.

import { captureException, startTransaction } from "@sentry/nextjs";
import useSWR, { SWRConfiguration, SWRResponse } from "swr";

import * as gqlTypes from "~src/__generated__/graphql/types";

import {
  LoginRequired,
  MissingPermission,
  UnmarshalFailure,
} from "~src/shared/apigen/errors";
import * as sharedTypes from "~src/shared/apigen/types";
import { useVendorImpersonateStore } from "~src/shared/auth/store";
import {
  FieldValidationError,
  InternalServerError,
  RPCError,
  NetworkError,
  UnknownRPCError,
  IRPCErrorOptions,
} from "~src/shared/errors/taxonomy";
import { makeAPIURL } from "~src/shared/env";

export type IVendorAcknowledgeApprovedForTradeReturn = Record<string, never>;

export const vendorAcknowledgeApprovedForTrade = async (
): Promise<IVendorAcknowledgeApprovedForTradeReturn> => {
  // Construct the headers.
  const headers: Record<string, string> = {
	"Content-Type": "application/json",
  };

  const { impersonateVendorPublicID } = useVendorImpersonateStore.getState();
  if (impersonateVendorPublicID !== null) {
    headers["X-Impersonate-Vendor"] = impersonateVendorPublicID;
  }

  // Start a Sentry transaction that wraps the HTTP request.
  const transaction = startTransaction({
    name: "pipe_rpc",
  });
  transaction.setTag("rpc.router", "vendor");
  transaction.setTag("rpc.route", "AcknowledgeApprovedForTrade");

  let response;
  try {
    response = await fetch(makeAPIURL() + "/v1/vendor/AcknowledgeApprovedForTrade", {
      method: "POST",
      cache: "no-store" as const,
      credentials: "include" as const,
      headers,
    });
  } catch (e) {
    throw new NetworkError(e?.message ?? "No error message", {
      router: "vendor",
      routePath: "AcknowledgeApprovedForTrade",
    });
  }

  let data;
  try {
    data = (await response.json()).data;
  } catch (e) {
    const error = new UnknownRPCError("Failed to decode JSON.", {
      cause: e,
      status: response.status,
	  data: {},
      router: "vendor",
      routePath: "AcknowledgeApprovedForTrade",
    });
    captureException(error);
    throw error;
  }

  transaction.setData("result.statusCode", response.status);
  transaction.finish();

  // First handle the generalized errors.
  const statusType = Math.floor(response.status / 100);
  if (statusType === 5) {
    const errorMessage = await response.text();
    throw new InternalServerError(errorMessage, {
      router: "vendor",
      routePath: "AcknowledgeApprovedForTrade",
    });
  }

  // Then handle the expected backend errors. All have a 400 status code.
  if (statusType !== 2) {
    const errorData = data as { code?: string };
    switch (errorData.code) {
      case "SHARED/RPC/LOGIN_REQUIRED":
        throw new LoginRequired("LOGIN_REQUIRED", {
          router: "vendor",
          routePath: "AcknowledgeApprovedForTrade",
        });
      case "SHARED/RPC/MISSING_PERMISSION":
        throw new MissingPermission("MISSING_PERMISSION", {
          router: "vendor",
          routePath: "AcknowledgeApprovedForTrade",
        });
      case "SHARED/RPC/UNMARSHAL_FAILURE":
        throw new UnmarshalFailure("UNMARSHAL_FAILURE", {
          router: "vendor",
          routePath: "AcknowledgeApprovedForTrade",
        });
      case "VENDOR/ACKNOWLEDGE_APPROVED_FOR_TRADE/IMPERSONATING":
        throw new VendorAcknowledgeApprovedForTradeImpersonating("IMPERSONATING");
      default:
        const error = new UnknownRPCError("An error occurred.", {
          code: errorData.code,
          status: response.status,
          data: errorData,
          router: "vendor",
          routePath: "AcknowledgeApprovedForTrade",
        });
        captureException(error);
        throw error;
    }
  }

  return data as IVendorAcknowledgeApprovedForTradeReturn;
};

export const useVendorAcknowledgeApprovedForTrade = (
  config?: SWRConfiguration,
): SWRResponse<IVendorAcknowledgeApprovedForTradeReturn, RPCError> => {
  return useSWR(
    "/v1/vendor/AcknowledgeApprovedForTrade",
    vendorAcknowledgeApprovedForTrade,
    config,
  );
};

type IRouteErrorOptions = Omit<IRPCErrorOptions, "status" | "router" | "routePath">;

export class VendorAcknowledgeApprovedForTradeImpersonating extends RPCError {
  
  constructor(message: string, options?: IRouteErrorOptions) {
    super(message, {
      ...options,
      code: options?.code ?? "VENDOR/ACKNOWLEDGE_APPROVED_FOR_TRADE/IMPERSONATING",
      expected: true,
      status: 400,
      router: "vendor",
      routePath: "AcknowledgeApprovedForTrade",
    });
  }
}
