import { css, useTheme } from "@emotion/react";
import styled from "@emotion/styled";
import { useTextField } from "@react-aria/textfield";
import React from "react";

import { BlueprintIcon, IIconProps } from "~src/designSystem/deprecated/BlueprintIcon";
import { PleaseGoUseFlexInstead } from "~src/designSystem/deprecated/Flexbox";
import { IBoxStyles, withBoxStyles } from "~src/shared/helpers/sharedComponentProps";

export type ITextFieldProps = {
  "label"?: string;
  "description"?: string;
  "errorMessage"?: string;
  "placeholder"?: string;
  "minLength"?: number;
  "maxLength"?: number;
  "pattern"?: string;
  "isDisabled"?: boolean;
  "value"?: string;
  "inputMode"?: "decimal";
  "leftIcon"?: IIconProps["icon"];
  "rightIcon"?: IIconProps["icon"];
  // why did react aria change the onChange api?
  "onChange"?: (value: string) => void;

  // If true, redacts the input from Logrocket.
  "data-private"?: boolean;
} & IBoxStyles &
  Pick<React.HTMLProps<HTMLInputElement>, "name" | "type" | "onBlur" | "autoComplete">;

export const TextField: React.FC<ITextFieldProps> = (props) => {
  const { label, description, errorMessage, leftIcon, rightIcon } = props;
  const ref = React.useRef(null);
  const { labelProps, inputProps, descriptionProps, errorMessageProps } = useTextField(props, ref);
  const theme = useTheme();

  return (
    <Wrapper>
      <Label {...labelProps}>{label}</Label>
      {/* This needs to be immediately before the <Input /> tag! */}
      <div style={{ position: "relative" }}>
        {leftIcon !== undefined && (
          <IconContainer isLeft>
            <BlueprintIcon
              aria-hidden="true"
              icon={leftIcon}
              size={16}
              color={theme.components.TextField.iconColor}
            />
          </IconContainer>
        )}
        <IconContainer>
          {rightIcon !== undefined && (
            <BlueprintIcon
              aria-hidden="true"
              icon={rightIcon}
              size={12}
              color={theme.components.TextField.iconColor}
            />
          )}
          {errorMessage !== undefined && (
            <BlueprintIcon
              aria-hidden="true"
              icon="warning-sign"
              size={12}
              color={theme.components.TextField.errorMessage}
            />
          )}
        </IconContainer>
      </div>
      <Input
        inputMode={props.inputMode ?? undefined}
        pattern={props.pattern ?? undefined}
        minLength={props.minLength ?? undefined}
        maxLength={props.maxLength ?? undefined}
        {...(inputProps as React.InputHTMLAttributes<HTMLInputElement>)}
        ref={ref}
        /** TODO:(Benny) manually passing error here, react aria may have a prop for this? validationState? */
        error={errorMessage !== undefined}
        leftIcon={leftIcon !== undefined}
        rightIcon={leftIcon !== undefined}
        data-private={props["data-private"]}
      />
      {errorMessage !== undefined && (
        <ErrorMessage {...errorMessageProps}>{errorMessage}</ErrorMessage>
      )}
      {description !== undefined && <Description {...descriptionProps}>{description}</Description>}
    </Wrapper>
  );
};

const Wrapper = styled(PleaseGoUseFlexInstead)`
  ${(props) => withBoxStyles(props)}
`;

const IconContainer = styled.div<{ isLeft?: boolean }>`
  position: absolute;
  padding: 12px 16px;
  margin-top: -1.5px;
  left: ${(props) => props.isLeft === true && 0};
  right: ${(props) => props.isLeft !== true && 0};
`;

const Label = styled.label`
  ${(props) => props.theme.textStyles.Regular.Body100}
  margin-bottom: 4px;
  color: ${(props) => props.theme.components.TextField.label};
`;
const Input = styled.input<{ error: boolean; leftIcon: boolean; rightIcon: boolean }>`
  ${(props) => props.theme.textStyles.Regular.Body200}
  padding: 8px 12px;
  border-radius: 6px;
  padding-left: ${(props) => props.leftIcon && "42px"};
  padding-right: ${(props) => props.rightIcon && "42px"};

  background: ${(props) => props.theme.components.TextField.background.default};
  color: ${(props) => props.theme.components.TextField.color.default};
  border: 1px solid ${(props) => props.theme.components.TextField.border.default};
  outline: 1px solid transparent;
  transition: ${(props) => props.theme.transition};

  &::placeholder {
    color: ${(props) => props.theme.components.TextField.color.placeholder};
  }

  &:hover {
    background: ${(props) => props.theme.components.TextField.background.hover};
    color: ${(props) => props.theme.components.TextField.color.hover};
    border: 1px solid ${(props) => props.theme.components.TextField.border.hover};
  }

  &:focus {
    background: ${(props) => props.theme.components.TextField.background.active};
    color: ${(props) => props.theme.components.TextField.color.active};
    border: 1px solid ${(props) => props.theme.components.TextField.border.active};
    outline: 1px solid ${(props) => props.theme.components.TextField.border.active}88;
    caret-color: ${(props) => props.theme.components.TextField.border.active};
  }

  &:disabled {
    background: ${(props) => props.theme.components.TextField.background.disabled};
    color: ${(props) => props.theme.components.TextField.color.disabled};
    border: 1px solid ${(props) => props.theme.components.TextField.border.disabled};
  }

  ${(props) =>
    props.error &&
    css`
      border: 1px solid ${props.theme.components.TextField.border.error};
      outline: 1px solid ${props.theme.components.TextField.border.error}88;
      caret-color: ${props.theme.components.TextField.border.error};
    `}
`;
const Description = styled.span`
  ${(props) => props.theme.textStyles.Regular.Body100}
  margin-top: 4px;
  color: ${(props) => props.theme.components.TextField.description};
`;
const ErrorMessage = styled.span`
  ${(props) => props.theme.textStyles.Regular.Body100}
  margin-top: 4px;
  color: ${(props) => props.theme.components.TextField.errorMessage};
`;
