import create from "zustand";

/**
 * The step in the inbox flow.
 */
export enum IInboxStep {
  /**
   * The initial inbox state.
   */
  INBOX = 0,
  /**
   * The review step.
   */
  REVIEW = 1,
  /**
   * Payout complete step.
   */
  COMPLETE = 2,
}

type IStore = {
  wizardStep: IInboxStep;
  setWizardStep: (arg0: IInboxStep) => void;
  isTermsChecked: boolean;
  setIsTermsChecked: (arg0: boolean) => void;
  isOrderSubmissionLoading: boolean;
  setIsOrderSubmissionLoading: (arg0: boolean) => void;
  resetInboxState: () => void;
};

export const useInboxState = create<IStore>((set) => ({
  wizardStep: IInboxStep.INBOX,
  setWizardStep: (wizardStep: IInboxStep) => set((state) => ({ ...state, wizardStep })),
  isTermsChecked: false,
  setIsTermsChecked: (isTermsChecked: boolean) => set((state) => ({ ...state, isTermsChecked })),
  isOrderSubmissionLoading: false,
  setIsOrderSubmissionLoading: (isOrderSubmissionLoading: boolean) =>
    set((state) => ({ ...state, isOrderSubmissionLoading })),
  resetInboxState: () =>
    set({ wizardStep: IInboxStep.INBOX, isTermsChecked: false, isOrderSubmissionLoading: false }),
}));
