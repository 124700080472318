import React from "react";

import { t } from "~src/designSystem/theme";

export const CircleCheck: React.FC = () => (
  <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
    <circle cx="10" cy="10" r="8" stroke={t.colors.contentGood.toString()} strokeWidth="1.5" />
    <path
      d="M6.25 10.1842L8.95 12.5L13.75 7"
      stroke={t.colors.contentGood.toString()}
      strokeWidth="1.5"
    />
  </svg>
);
