import React, { useEffect, useMemo, useState } from "react";

import {
  IBankAccount,
  isValidBankAccountOption,
} from "~/src/shared/bankAccounts/createBankAccountOptions";
import { useAuthContext } from "~src/shared/auth/AuthProvider";
import { useUser } from "~src/shared/auth/useUser";

import {
  PayoutAccountsVendorQuery,
  usePayoutAccountsVendorQuery,
} from "./__generated__/PayoutAccounts";

type IVendor = PayoutAccountsVendorQuery["vendors"][number];

export type IPayoutAccount = IBankAccount;

interface IPayoutAccountsContext {
  vendor?: IVendor;
  refetchVendor: () => Promise<void>;
  accounts?: IPayoutAccount[];
  payoutTo?: IPayoutAccount;
  setPayoutMethod: (arg0: string) => void;
}

export const PayoutAccountsContext = React.createContext<IPayoutAccountsContext>({
  vendor: undefined,
  refetchVendor: async () => undefined,
  accounts: [],
  payoutTo: undefined,
  setPayoutMethod: () => undefined,
});

interface IProps {
  children?: React.ReactNode;
}

export const PayoutAccountsProvider: React.FC<IProps> = ({ children }) => {
  const [vendor, setVendor] = useState<IVendor | undefined>(undefined);
  const [payoutMethod, setPayoutMethod] = useState<string | undefined>(undefined);

  const vendorID = useUser().vendor.id;

  const { revalidate } = useAuthContext();

  const { data, refetch } = usePayoutAccountsVendorQuery({
    variables: { vendorID },
  });

  useEffect(() => {
    const incomingVendor = data?.vendors[0];
    if (incomingVendor === undefined) {
      setPayoutMethod(undefined);
      setVendor(undefined);
    } else {
      setPayoutMethod(incomingVendor.payout_method?.public_id);
      setVendor(incomingVendor);
    }
  }, [data]);

  // Compile a list of bank accounts that can potentially be a payout method.
  const accounts = useMemo(() => vendor?.accounts.filter(isValidBankAccountOption), [vendor]);

  const payoutTo = useMemo(
    () => vendor?.accounts.find((a) => a.public_id === payoutMethod),
    [vendor, payoutMethod],
  );

  const refetchVendor = async () => {
    await revalidate();
    await refetch({ vendorID });
  };

  // This effect ensures that the current payout method is valid. Whenever the
  // accounts list or payout method updates, ensure that it is valid (or set
  // it to the default, which is the checking account with the highest balance).
  useEffect(() => {
    // If the payout method is already set, we should NEVER attempt to change it
    // automatically.
    if (payoutMethod !== undefined) {
      return;
    }

    // If there are no accounts, bail.
    if (accounts === undefined || accounts.length === 0) {
      return;
    }

    // Set the checking account with the highest balance as our payout method.
    const bestPayoutMethod = accounts.reduce((account, x) => {
      if (x.subtype === "checking" && x.balance_current > account.balance_current) {
        return x;
      }
      return account;
    });

    setPayoutMethod(bestPayoutMethod.public_id);
  }, [accounts, payoutMethod]);

  const value: IPayoutAccountsContext = {
    vendor,
    refetchVendor,
    accounts,
    payoutTo,
    setPayoutMethod,
  };

  return <PayoutAccountsContext.Provider value={value}>{children}</PayoutAccountsContext.Provider>;
};

graphql`
  query PayoutAccountsVendorQuery($vendorID: String!) {
    vendors(where: { public_id: { _eq: $vendorID } }) {
      public_id
      rate_months_1
      payout_method {
        public_id
      }
      accounts {
        ...createBankAccountOptions_account
      }
    }
  }
`;
