/** @jsxImportSource @emotion/react */
import { css } from "@emotion/react";
import { sum } from "lodash";
import React, { useCallback, useEffect, useState } from "react";

import { PleaseGoUseFlexInstead } from "~src/designSystem/deprecated/Flexbox";
import { OldHorizontalRuleDontUse } from "~src/designSystem/deprecated/HorizontalRule";
import { BackButton } from "~src/designSystem/molecules/BackButton";
import { Radio, RadioGroup } from "~src/designSystem/molecules/RadioGroup";
import {
  RadioDefaultRow,
  RadioGroupDefaultHeader,
} from "~src/designSystem/molecules/RadioGroup/layout";
import { t } from "~src/designSystem/theme";
import { formatBidPrice, formatCents } from "~src/shared/helpers/format";
import { useNotNullContext } from "~src/shared/helpers/useNotNullContext";
import { ISegmentTrackEvent, useAnalytics } from "~src/shared/thirdParties/segment";

import { ProposalsContext } from "../../contexts/ProposalsContext";
import { IInboxStep, useInboxState } from "../../state";
import { calculatePayoutFromTradeableContract } from "../../utils/adjustableTerms";
import { AgreementCheckBox } from "./AgreementCheckBox";
import { SubmitPayout } from "./SubmitPayout";

interface TermRadioData {
  id: string;
  term: string;
  bidPrice: string;
  amount: string;
}

interface AdjustableTermsScreenProps {
  setIsSelectingTermLength: (arg0: boolean) => void;
}

export const AdjustableTermsScreen: React.FC<AdjustableTermsScreenProps> = (props) => {
  // Track the event in segment
  const { trackEvent } = useAnalytics();

  const [terms, setTerms] = useState<TermRadioData[]>();

  const { selectedProposals, termsData, selectedTerm, setSelectedTerm } =
    useNotNullContext(ProposalsContext);

  const wizardStep = useInboxState((state) => state.wizardStep);

  const noProposalsSelected = selectedProposals.length === 0;

  // TODO: this should be fetched in ProposalsContext so that we eagerly load it at ppage load time.

  useEffect(() => {
    const formattedData = termsData.map((term) => {
      const selectedProposalsValueCents = sum(
        selectedProposals.map((curr) =>
          calculatePayoutFromTradeableContract(curr, term.termLengthMonths),
        ),
      );

      const payoutAmountFormatted = formatCents(
        Math.min(selectedProposalsValueCents, term.tradeLimit),
        term.currency,
      );
      return {
        id: term.termLengthMonths.toString(),
        term: `${term.termLengthMonths} months`,
        bidPrice: formatBidPrice(term.bidPriceBPS / 10000, term.currency),
        amount: noProposalsSelected ? "" : payoutAmountFormatted,
      };
    });
    setTerms(formattedData);
  }, [noProposalsSelected, selectedProposals, termsData]);

  const handleBackButtonClick = useCallback(() => {
    trackEvent(ISegmentTrackEvent.TradingOrderBoxViewAdjustableTermsBackArrowClick);
    props.setIsSelectingTermLength(false);
  }, [trackEvent, props]);

  const handleRadioInputChange = useCallback(
    (val: string) => {
      const newTermLength = parseInt(val, 10);
      trackEvent(ISegmentTrackEvent.TradingOrderBoxChangeAdjustableTermsRadioClick, {
        newTermLength,
      });
      // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
      setSelectedTerm(termsData.filter((term) => term.termLengthMonths === newTermLength)[0]!);
    },
    [setSelectedTerm, termsData, trackEvent],
  );

  return (
    <PleaseGoUseFlexInstead paddingBottom="20px" paddingTop="8px">
      <BackButton
        css={css`
          padding-top: ${t.space[3].toString()};
        `}
        onClick={handleBackButtonClick}
      >
        Back to Summary
      </BackButton>
      <>
        <RadioGroup
          onChange={handleRadioInputChange}
          value={selectedTerm.termLengthMonths.toString()}
          headerComponent={
            <RadioGroupDefaultHeader
              leftHeaderLabel="Term Length"
              rightHeaderLabel="Payout Amount"
            />
          }
        >
          {terms !== undefined &&
            terms.map((term) => (
              <Radio key={term.id} value={term.id}>
                <RadioDefaultRow
                  primaryText={term.term}
                  secondaryText={`Bid Price ${term.bidPrice}`}
                  valueText={term.amount}
                />
              </Radio>
            ))}
        </RadioGroup>
      </>
      <OldHorizontalRuleDontUse color="box" marginTop="12px" marginX="32px" />

      {wizardStep === IInboxStep.REVIEW && (
        <PleaseGoUseFlexInstead paddingX="32px">
          <AgreementCheckBox />
        </PleaseGoUseFlexInstead>
      )}
      <SubmitPayout onReviewOrderClick={() => props.setIsSelectingTermLength(false)} />
    </PleaseGoUseFlexInstead>
  );
};
