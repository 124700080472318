/** @jsxImportSource @emotion/react */
import { css, useTheme } from "@emotion/react";
import numeral from "numeral";
import React, { useEffect } from "react";
import {
  VictoryAxis,
  VictoryBar,
  VictoryChart,
  VictoryThemeDefinition,
  VictoryTooltip,
} from "victory";

import { PleaseGoUseFlexInstead } from "~src/designSystem/deprecated/Flexbox";
import { OldHeadingUseTextInstead } from "~src/designSystem/deprecated/Heading";
import { OldHorizontalRuleDontUse } from "~src/designSystem/deprecated/HorizontalRule";
import { ReallyOldParagraphDontUse } from "~src/designSystem/deprecated/Paragraph.old";
import { LoadingDots } from "~src/designSystem/loading/LoadingDots";
import { victoryTheme } from "~src/shared/charts/chartTheme";
import { abbrevMonthsList } from "~src/shared/constants";
import { currencySymbol } from "~src/shared/helpers/format";
import { ISegmentTrackEvent, useAnalytics } from "~src/shared/thirdParties/segment";

import { IInboxStep, useInboxState } from "../../state";
import { AgreementCheckBox } from "./AgreementCheckBox";
import { IPaymentScheduleGraphData } from "./calculatePaymentSchedule";
import { EmptyState } from "./EmptyState";
import { SubmitPayout } from "./SubmitPayout";

const baseBarWidth = 16;
const baseNumSchedules = 12;
const numXAxisLabels = 6;

interface PaymentScheduleTabProps {
  scheduleData: IPaymentScheduleGraphData | null;
  hasSelectedProposals: boolean;
}

export const PaymentScheduleTab: React.FC<PaymentScheduleTabProps> = ({
  scheduleData,
  hasSelectedProposals,
}) => {
  const psTheme = useTheme().components.PaymentSchedule;

  // Track the event in segment
  const { trackEvent } = useAnalytics();
  useEffect(() => {
    trackEvent(ISegmentTrackEvent.TradingPageScheduleButtonClick);
  }, [trackEvent]);

  const wizardStep = useInboxState((state) => state.wizardStep);

  return (
    <PleaseGoUseFlexInstead
      initial={{ opacity: 0 }}
      animate={{ opacity: 1 }}
      exit={{ opacity: 0 }}
      padBottom
    >
      <PleaseGoUseFlexInstead pad>
        <OldHeadingUseTextInstead size="20px">Payment Schedule</OldHeadingUseTextInstead>
        <ReallyOldParagraphDontUse color="#FFF" lineHeight="1.4">
          Your payment schedule is just what your customers are currently paying you. Nothing
          changes!
        </ReallyOldParagraphDontUse>
      </PleaseGoUseFlexInstead>
      <OldHorizontalRuleDontUse
        color="default"
        css={css`
          margin: 0 20px;
        `}
      />
      {(() => {
        if (!hasSelectedProposals) {
          return <EmptyState />;
        }

        // If scheduleData is null and selectedProposalIDs.length > 0, implies we're still loading data
        if (scheduleData === null) {
          return (
            <PleaseGoUseFlexInstead padTop height="254px" margin="auto">
              <LoadingDots />
            </PleaseGoUseFlexInstead>
          );
        }

        const symbol = currencySymbol(scheduleData.currency);

        const numBars = scheduleData.schedule.length;
        const multiple = numBars / baseNumSchedules;
        const barWidth = Math.round(baseBarWidth / multiple);

        /**
         * We want to show a maximum of term length months in the x-axis labels. So
         * evenly spread out the months to show.
         */
        const windowSize = Math.round(numBars / numXAxisLabels);
        const today = new Date();
        const thisMonthIndex = today.getMonth();
        const xAxisLabels: { label: string; x: number }[] = scheduleData.schedule.map(
          (entry, i) => {
            if (i % windowSize === 0) {
              const monthIndex = entry.monthsFromToday + thisMonthIndex;
              return {
                label: abbrevMonthsList[monthIndex % 12] as string,
                x: entry.monthsFromToday,
              };
            }
            return { label: "", x: entry.monthsFromToday };
          },
        );

        return (
          <PleaseGoUseFlexInstead padTop>
            <VictoryChart
              // TODO(benny)/TODO(igm): unsafe cast
              theme={victoryTheme as unknown as VictoryThemeDefinition}
              // Chart styles
              height={197}
              domainPadding={{ x: [40, 20], y: 10 }}
              padding={{ top: 5, bottom: 50, left: 65, right: 20 }}
              // Animations
              animate={{
                // https://github.com/FormidableLabs/victory/issues/848#issuecomment-654830767
                onLoad: { duration: 1 },
                duration: 500,
              }}
            >
              {/* X-axis */}
              <VictoryAxis
                // tickValues specifies both the number of tick labels and where
                // those tick labels are placed on the axis
                tickValues={xAxisLabels.map((entry) => entry.x)}
                tickFormat={xAxisLabels.map((entry) => entry.label)}
                gridComponent={<span />}
                style={{
                  axis: { stroke: psTheme.axis, strokeWidth: 1 },
                  ticks: { stroke: psTheme.stroke, strokeWidth: 0 },
                  tickLabels: {
                    fontSize: 12,
                    fill: psTheme.tickLabel,
                    fontFamily: "Inter",
                  },
                }}
              />
              {/* Y-axis */}
              <VictoryAxis
                dependentAxis
                tickCount={4}
                tickFormat={
                  // TODO: major unit conversion
                  (y) => (Number.isInteger(y) ? numeral(y / 100).format(`${symbol}0a`) : 0)
                }
                padding={{ right: 60 }}
                style={{
                  axis: { stroke: psTheme.axis, strokeWidth: 0 },
                  // axisLabel: { fontSize: 20, padding: 30 },
                  grid: { stroke: psTheme.stroke },
                  ticks: { stroke: psTheme.tick, strokeWidth: 1 },
                  tickLabels: { fontSize: 12, fill: psTheme.tickLabel },
                }}
              />
              <VictoryBar
                data={scheduleData.schedule}
                // data accessor for x values
                x="monthsFromToday"
                // data accessor for y values
                y="amount"
                // Tooltip settings
                labelComponent={
                  <VictoryTooltip
                    cornerRadius={4}
                    pointerLength={0}
                    pointerWidth={0}
                    //  SVG style properties for the rendered flyout container.
                    flyoutStyle={{
                      fill: psTheme.flyout,
                    }}
                    // SVG style properties for the rendered <text> element.
                    style={{
                      fill: psTheme.flyoutText,
                      fontSize: 10,
                      fontFamily: "Inter",
                    }}
                  />
                }
                // Events
                events={[
                  {
                    target: "data",
                    eventHandlers: {
                      onMouseOver: () => {
                        return [
                          {
                            target: "data",
                            mutation: () => ({
                              style: {
                                fill: "#fff",
                                width: barWidth,
                              },
                            }),
                          },
                          {
                            target: "labels",
                            mutation: () => ({ active: true }),
                          },
                        ];
                      },
                      onMouseOut: () => {
                        return [
                          {
                            target: "data",
                            mutation: () => {
                              // nothing
                            },
                          },
                          {
                            target: "labels",
                            mutation: () => ({ active: false }),
                          },
                        ];
                      },
                    },
                  },
                ]}
                // Bar styles
                cornerRadius={2.12}
                style={{
                  data: {
                    fill: psTheme.bar,
                    width: barWidth,
                  },
                }}
              />
            </VictoryChart>
          </PleaseGoUseFlexInstead>
        );
      })()}
      {wizardStep === IInboxStep.REVIEW && (
        <PleaseGoUseFlexInstead paddingX="32px">
          <AgreementCheckBox />
        </PleaseGoUseFlexInstead>
      )}
      <SubmitPayout />
    </PleaseGoUseFlexInstead>
  );
};
