import * as Types from '../../../../../../__generated__/graphql/types';

import { gql } from '@apollo/client';
import { fragment_createBankAccountOptions_account } from '../../../../../../shared/bankAccounts/__generated__/createBankAccountOptions';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type PayoutAccountsVendorQueryVariables = Types.Exact<{
  vendorID: Types.Scalars['String'];
}>;


export type PayoutAccountsVendorQuery = { readonly __typename?: 'query_root', readonly vendors: ReadonlyArray<{ readonly __typename?: 'vendors', readonly public_id: string, readonly rate_months_1: number, readonly payout_method: { readonly __typename?: 'accounts', readonly public_id: string } | null, readonly accounts: ReadonlyArray<{ readonly __typename?: 'accounts', readonly public_id: string, readonly name: string, readonly mask: string, readonly subtype: string | null, readonly supports_money_movement: boolean, readonly is_excluded: boolean, readonly balance_current: number, readonly plaid_item: { readonly __typename?: 'plaid_items', readonly public_id: string, readonly institution_name: string, readonly institution_logo: string | null } | null }> }> };


export const PayoutAccountsVendorQueryDocument = gql`
    query PayoutAccountsVendorQuery($vendorID: String!) {
  vendors(where: {public_id: {_eq: $vendorID}}) {
    public_id
    rate_months_1
    payout_method {
      public_id
    }
    accounts {
      ...createBankAccountOptions_account
    }
  }
}
    ${fragment_createBankAccountOptions_account}`;

/**
 * __usePayoutAccountsVendorQuery__
 *
 * To run a query within a React component, call `usePayoutAccountsVendorQuery` and pass it any options that fit your needs.
 * When your component renders, `usePayoutAccountsVendorQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = usePayoutAccountsVendorQuery({
 *   variables: {
 *      vendorID: // value for 'vendorID'
 *   },
 * });
 */
export function usePayoutAccountsVendorQuery(baseOptions: Apollo.QueryHookOptions<PayoutAccountsVendorQuery, PayoutAccountsVendorQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<PayoutAccountsVendorQuery, PayoutAccountsVendorQueryVariables>(PayoutAccountsVendorQueryDocument, options);
      }
export function usePayoutAccountsVendorQueryLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<PayoutAccountsVendorQuery, PayoutAccountsVendorQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<PayoutAccountsVendorQuery, PayoutAccountsVendorQueryVariables>(PayoutAccountsVendorQueryDocument, options);
        }
export type PayoutAccountsVendorQueryHookResult = ReturnType<typeof usePayoutAccountsVendorQuery>;
export type PayoutAccountsVendorQueryLazyQueryHookResult = ReturnType<typeof usePayoutAccountsVendorQueryLazyQuery>;
export type PayoutAccountsVendorQueryQueryResult = Apollo.QueryResult<PayoutAccountsVendorQuery, PayoutAccountsVendorQueryVariables>;