import React, { useCallback } from "react";
import toast from "react-hot-toast";

import { adminRequests } from "~src/admin/requests";

import { callRequest } from "../requests/useRequest";
import { useStepper } from "../stepper/stepperContext";
import { DataSourceLayout } from "./components/dataSourceLayout";

type IUseOnDeleteDataSourceArgs = {
  refetchDataSources: () => Promise<void>;
};

export const useOnDeleteDataSource = (
  args: IUseOnDeleteDataSourceArgs,
): ((publicID: string) => void) => {
  const { addAndOpenStepperDialog, clearStepperStack } = useStepper();

  const [isSubmitting, setIsSubmitting] = React.useState<boolean>(false);

  const onConfirmDelete = useCallback(
    async (publicID: string) => {
      setIsSubmitting(true);
      const res = await callRequest(adminRequests.deleteDataSource({ publicID }), {
        handleRPCError: (err) => {
          if ((err.errorMessage ?? "") !== "") {
            toast.error(err.errorMessage);
          }
          return true;
        },
      });
      if (res.ok) {
        setIsSubmitting(false);
        toast("Deletion successfully kicked off.");
        clearStepperStack();
        await args.refetchDataSources();
      } else {
        setIsSubmitting(false);
        toast.error("Deletion kickoff failed.");
      }
    },
    [clearStepperStack, args],
  );

  return (publicID: string) => {
    addAndOpenStepperDialog({
      component: (
        <DataSourceLayout
          heading={`Are you sure you want to delete ${publicID} ?`}
          description="Vendor will have to reconnect their data source."
          secondaryButtonText="Close"
          secondaryButtonAction={clearStepperStack}
          primaryButtonText="Confirm"
          isPrimaryButtonLoading={isSubmitting}
          primaryButtonAction={() => onConfirmDelete(publicID)}
        />
      ),
      config: {
        title: "Delete data source",
      },
    });
  };
};
