/* eslint-disable react/style-prop-object */
import React from "react";

import { t } from "~src/designSystem/theme";

export type IProps = {
  fill?: string;
};

export const StatementIcon: React.FC<IProps> = ({ fill }) => (
  <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M21.5999 2.40039H2.3999V21.6004H21.5999V2.40039ZM5.9999 10.5004H17.9999V8.70039H5.9999V10.5004ZM17.9999 17.7004H5.9999V15.9004H17.9999V17.7004ZM5.9999 14.1004H17.9999V12.3004H5.9999V14.1004Z"
      fill={fill ?? `${t.colors.strokeElevate}`}
    />
  </svg>
);
