import { css } from "@emotion/react";
import styled from "@emotion/styled";
import { useButton } from "@react-aria/button";
import { OverlayContainer, useOverlayPosition, useOverlayTrigger } from "@react-aria/overlays";
import { HiddenSelect, useSelect } from "@react-aria/select";
import { useSelectState } from "@react-stately/select";
import React from "react";

import { StatelessListBox } from "~src/designSystem/atoms/ListBox";
import { IPortalPlacement, Portal } from "~src/designSystem/atoms/Portal";
import { BlueprintIcon } from "~src/designSystem/deprecated/BlueprintIcon";
import { t } from "~src/designSystem/theme";

type ISize = "medium" | "small";

export type ISelectProps = {
  "className"?: string;

  "label": React.ReactNode;
  /**
   * Whether to show the label above the select.
   * @default true
   */
  "showSelectLabel"?: boolean;
  /**
   * Whether to show the label inside the ListBox.
   * @default false
   */
  "showListBoxLabel"?: boolean;

  "name": string;

  /**
   * Whether to add a visual divider line after given indices
   */
  "dividerAfter"?: number[];

  /**
   * The text shown when no item is selected.
   * @default "Select an option"
   */
  "placeholder"?: string;

  "children": React.ReactElement | React.ReactElement[];

  "selectedKey": string | number | undefined;
  "onSelectionChange": (key: string) => void;

  /**
   * @default medium
   */
  "size"?: ISize;

  /**
   * @default false
   */
  "isDisabled"?: boolean;

  /**
   * Where to attach the popover to the select button. This is best-effort; if the
   * screen space does not allow it, the best option will be chosen.
   * @default "bottom left"
   */
  "portalPlacement"?: IPortalPlacement;

  /**
   * Width of the popover containing the options.
   * defaults to 100% of the SelectButton width
   */
  "popoverWidth"?: string;

  /** Changes background color to a darker theme to accomodate different contexts */
  "darkBackground"?: boolean;

  /** When set places error message below input and turns border red */
  "errorMessage"?: string;

  "id"?: string;

  // If true, redacts the value from Logrocket.
  "data-private"?: boolean;
};

/**
 * A preliminary Select component. Designed for permissions work; we will need to
 * refactor this API to be more general when more use cases appear.
 *
 * Future work can make the popover support several other "alignments."
 *
 * See the react-aria docs for more information:
 * https://react-spectrum.adobe.com/react-aria/useSelect.html.
 */
export const Select: React.FC<ISelectProps> = (props) => {
  const state = useSelectState(props);

  const placeholderText = props.placeholder ?? "Select an option";

  const triggerRef = React.useRef<HTMLButtonElement>(null);
  const overlayRef = React.useRef(null);

  // Get props for the trigger and overlay. This also handles
  // hiding the overlay when a parent element of the trigger scrolls
  // (which invalidates the popover positioning).
  const { triggerProps: overlayTriggerProps, overlayProps } = useOverlayTrigger(
    { type: "dialog" },
    state,
    triggerRef,
  );

  // Get popover positioning props relative to the trigger
  const { overlayProps: positionProps } = useOverlayPosition({
    targetRef: triggerRef,
    overlayRef,
    placement: props.portalPlacement ?? "bottom left",
    offset: 8,
    shouldFlip: false,
    isOpen: state.isOpen,
  });

  const {
    labelProps,
    triggerProps: selectTriggerProps,
    valueProps,
    menuProps,
  } = useSelect(props, state, triggerRef);

  const { buttonProps } = useButton(selectTriggerProps, triggerRef);

  const isSelectedItemEmpty =
    /* eslint-disable-next-line @typescript-eslint/no-unnecessary-condition */
    state.selectedItem === null ||
    /* eslint-disable-next-line @typescript-eslint/no-unnecessary-condition */
    state.selectedItem === undefined ||
    /* eslint-disable-next-line @typescript-eslint/no-unnecessary-condition */
    props.selectedKey === null ||
    props.selectedKey === undefined;

  const showMenu = state.isOpen && props.isDisabled !== true;

  return (
    <div className={props.className}>
      {props.label !== null && props.showSelectLabel !== false && (
        <SelectLabel {...labelProps}>{props.label}</SelectLabel>
      )}
      <HiddenSelect state={state} triggerRef={triggerRef} label={props.label} name={props.name} />
      <SelectButton
        {...buttonProps}
        {...overlayTriggerProps}
        ref={triggerRef}
        size={props.size ?? "medium"}
        isActive={showMenu}
        isDisabled={props.isDisabled === true}
        darkBackground={props.darkBackground === true}
        error={props.errorMessage !== undefined}
      >
        <SelectValue
          className="select-value"
          {...valueProps}
          isEmpty={isSelectedItemEmpty}
          data-private={props["data-private"]}
        >
          {(() => {
            if (isSelectedItemEmpty) {
              return placeholderText;
            }
            // We render the textValue if it is available. We do this because our
            // ListBox supports "Complex" and "Simple" items. Listboxes with complex
            // items have descriptions, and we don't want to render those in the select
            // value. The text value should equal the label for these items. For
            // listboxes with simple items, the textValue and rendered should be equal.
            if (state.selectedItem.textValue !== "") {
              return state.selectedItem.textValue;
            }
            return state.selectedItem.rendered;
          })()}
        </SelectValue>
        <CenteredIcon
          className="select-icon"
          aria-hidden="true"
          icon="caret-down"
          size={13}
          isDown={!showMenu}
          isEmpty={isSelectedItemEmpty}
        />
      </SelectButton>
      {props.errorMessage !== undefined && <ErrorMessage>{props.errorMessage}</ErrorMessage>}
      {showMenu && (
        <OverlayContainer>
          <Portal
            isDismissable
            isOpen={state.isOpen}
            onClose={state.close}
            ref={overlayRef}
            {...overlayProps}
            {...positionProps}
          >
            <ListBoxWrapper
              width={
                props.popoverWidth !== undefined
                  ? props.popoverWidth
                  : `${triggerRef.current?.offsetWidth}px`
              }
            >
              <StatelessListBox
                label={props.showListBoxLabel === true ? props.label : undefined}
                dontStyleActive
                borderRadius={t.radii[4].toString()}
                {...menuProps}
                state={state}
                dividerAfter={props.dividerAfter}
              />
            </ListBoxWrapper>
          </Portal>
        </OverlayContainer>
      )}
    </div>
  );
};

const SelectLabel = styled.div`
  ${(props) => props.theme.textStyles.Regular.Body100}
  margin-bottom: 4px;
  color: ${(props) => props.theme.components.Select.title};
`;

type ISelectButtonProps = {
  isDisabled: boolean;
  size: ISize;
  isActive: boolean;
  darkBackground: boolean;
  error: boolean;
};

const SelectButton = styled.button<ISelectButtonProps>`
  width: 100%;
  display: flex;
  flex-flow: row;
  justify-content: space-between;
  align-items: center;
  cursor: ${(props) => props.isDisabled || "pointer"};

  transition: ${(props) => props.theme.transition};

  background: ${(props) =>
    props.darkBackground
      ? props.theme.components.Select.background.active
      : props.theme.components.Select.background.default};
  border-radius: ${t.radii[4].toString()};
  border: 1px solid ${(props) => props.theme.components.Select.border.default};
  outline: 1px solid transparent;

  gap: ${t.space[2].toString()};
  padding: ${(props) => {
    switch (props.size) {
      case "small":
        return t.c.spacing("1", "2");
      case "medium":
        return t.c.spacing("2", "3");
    }
  }};

  line-height: 1.2;

  ${(props) =>
    props.isDisabled !== true &&
    css`
      &:hover {
        background: ${props.darkBackground
          ? props.theme.components.Select.background.active
          : props.theme.components.Select.background.hover};
        .select-value,
        .select-icon {
          color: ${props.theme.components.Select.color.hover};
        }
        border-color: ${props.theme.components.Select.border.hover};
      }
    `}

  ${(props) =>
    props.isActive &&
    css`
      background: ${props.theme.components.Select.background.active};
      border-color: ${props.theme.components.Select.border.active};
      outline: 1px solid ${props.theme.components.Select.border.active}80;
    `}

  ${(props) =>
    props.isDisabled &&
    css`
      .select-value,
      .select-icon {
        color: ${props.theme.components.Select.color.disabled};
      }
      background: ${props.theme.components.Select.background.disabled};
    `}

    ${(props) =>
    props.error &&
    css`
      border-color: ${props.theme.components.Select.border.error};
      outline: 1px solid ${props.theme.components.Select.border.error}88;
      caret-color: ${props.theme.components.Select.border.error};
    `}
`;

const SelectValue = styled.span<{ isEmpty?: boolean }>`
  ${(props) => props.theme.textStyles.Regular.Body200}
  color: ${(props) =>
    props.isEmpty === true
      ? props.theme.components.Select.color.empty
      : props.theme.components.Select.color.default};
  transition: ${(props) => props.theme.transition};
`;

const CenteredIcon = styled(BlueprintIcon)<{ isDown: boolean; isEmpty: boolean }>`
  display: flex;
  align-items: center;
  color: ${(props) =>
    props.isEmpty === true
      ? props.theme.components.Select.color.empty
      : props.theme.components.Select.color.default};
  svg {
    transform: ${(props) => props.isDown !== true && "rotate(180deg)"};
  }
  transition: ${(props) => props.theme.transition};
`;

const ListBoxWrapper = styled.div<{ width: string }>`
  width: ${(props) => props.width};
  max-height: 300px;
  border-radius: ${t.radii[4].toString()};
  animation: 0.25s ease-in-out;
  background-color: ${(props) => props.theme.components.Select.background.active};
  overflow: auto;
`;

const ErrorMessage = styled.span`
  ${(props) => props.theme.textStyles.Regular.Body100}
  margin-top: 4px;
  color: ${(props) => props.theme.components.Select.color.error};
`;
