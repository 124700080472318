/** @jsxImportSource @emotion/react */
import { css, useTheme } from "@emotion/react";
import React from "react";
import { GoAlert } from "react-icons/go";

import { Slider } from "~src/designSystem/atoms/Slider";
import { PleaseGoUseFlexInstead } from "~src/designSystem/deprecated/Flexbox";
import { OldSpanUseTextInstead } from "~src/designSystem/deprecated/Span";
import { formatCents } from "~src/shared/helpers/format";
import { useNotNullContext } from "~src/shared/helpers/useNotNullContext";
import { ISegmentTrackEvent, useAnalytics } from "~src/shared/thirdParties/segment";
import { ProposalsContext } from "~src/vendor/inbox/contexts/ProposalsContext";
import { useSupportedCurrency } from "~src/vendor/utils/useSupportedCurrencies";

export const OrderBoxSlider: React.FC = () => {
  const { trackEvent } = useAnalytics();
  const currency = useSupportedCurrency();
  const theme = useTheme();

  const {
    remainingPayoutLimit,
    selectedProposals,
    selectedProposalsPayoutValueCents,
    remainingSelectedPayoutLimit,
    maxPayoutForAllContracts,
    selectProposalsByMaxPayout,
  } = useNotNullContext(ProposalsContext);

  const sliderMax = Math.min(remainingPayoutLimit, maxPayoutForAllContracts);

  return (
    <>
      <Slider
        aria-label="Amount"
        minValue={0}
        maxValue={sliderMax}
        value={selectedProposalsPayoutValueCents}
        onChange={selectProposalsByMaxPayout}
        onChangeEnd={() => {
          // Track slider last amount in Segment
          trackEvent(ISegmentTrackEvent.TradingTradePageSliderClick, {
            slider_selected_amount: formatCents(selectedProposalsPayoutValueCents, currency),
            slider_max_amount: formatCents(sliderMax, currency),
          });
        }}
        showValue={false}
      />
      <PleaseGoUseFlexInstead row alignItems="center" justifyContent="space-between">
        <OldSpanUseTextInstead color="subtitle">
          {selectedProposals.length === 0 ? (
            <OldSpanUseTextInstead color="subtitle">
              Slide to select customers
            </OldSpanUseTextInstead>
          ) : remainingSelectedPayoutLimit > 0 ? (
            `${formatCents(remainingSelectedPayoutLimit, currency)} remaining trading limit`
          ) : (
            <PleaseGoUseFlexInstead row alignItems="center">
              <GoAlert
                css={() => css`
                  color: ${theme.components.OrderBox.Slider.alert.error};
                `}
              />
              <OldSpanUseTextInstead color="subtitle">
                &nbsp; You are {formatCents(-remainingSelectedPayoutLimit, currency)} over your
                trading limit.
              </OldSpanUseTextInstead>
            </PleaseGoUseFlexInstead>
          )}
        </OldSpanUseTextInstead>
        <OldSpanUseTextInstead
          color="subtitle"
          onClick={() => selectProposalsByMaxPayout(sliderMax)}
        >
          Max
        </OldSpanUseTextInstead>
      </PleaseGoUseFlexInstead>
    </>
  );
};
