import create from "zustand";

import { IRequirement } from "~src/shared/dataSourcesRequirements/hooks/useFetchDataSourceRequirements";
import { IVendor } from "~src/shared/types";

type IClientAckedApprovalStore = {
  ackedApproval: boolean;
  setAckedApproval: (arg0: boolean) => void;
};

// We locally store whether or not the client approved the page, since this can diverge
// from server state. It most notably diverges in the impersonation case, where we
// want to pass the Approved page as an impersonating user, but don't want the vendor to
// skip the page.
//
// Sales team can demo the inbox to newly approved vendors. Not being able to bypass the
// approved page while impersonating inhibits this.
export const useClientAckedApproval = create<IClientAckedApprovalStore>((set) => ({
  ackedApproval: false,
  setAckedApproval: (ackedApproval) => set({ ackedApproval }),
}));

export enum IVendorInboxState {
  APPROVED = "APPROVED",
  CAN_TRADE = "CAN_TRADE",
  DECLINED = "DECLINED",
  IN_REVIEW = "IN_REVIEW",
  USER_ACTION_REQUIRED = "USER_ACTION_REQUIRED",
}

export const computeInboxStateForVendor = (
  vendor: IVendor,
  requirements: IRequirement[],
  clientAckedApproval: boolean,
): IVendorInboxState => {
  // First, check if any existing data sources need to be reconnected or if any
  // additional data sources are required, regardless of whether the vendor is
  // declined (we periodically approve vendors who were previously declined).
  const relinkOrAdditionalRequired = requirements.some((req) => req.type !== "initial");
  if (relinkOrAdditionalRequired) {
    return IVendorInboxState.USER_ACTION_REQUIRED;
  }

  // No relinks required and vendor is declined.
  if (vendor.isDeclined) {
    return IVendorInboxState.DECLINED;
  }

  // Vendor is not declined, vendor has to satisfy data source
  // requirements (at this point "initial")
  if (requirements.length > 0) {
    return IVendorInboxState.USER_ACTION_REQUIRED;
  }

  // Vendor has no data source requirements to satisfy but they are not
  // approved to trade yet.
  if (!vendor.canTrade) {
    return IVendorInboxState.IN_REVIEW;
  }

  // Vendor is approved to trade but hasn't yet acknowledged the "Approved"
  // congratulatory screen.
  if (vendor.ackedApprovedForTradeAt === null && !clientAckedApproval) {
    return IVendorInboxState.APPROVED;
  }

  // Otherwise vendor can trade.
  return IVendorInboxState.CAN_TRADE;
};
