/* eslint-disable react/display-name */
import { useRouter } from "next/router";
import React, { useMemo, useState } from "react";
import toast from "react-hot-toast";

import { Button } from "~src/designSystem/atoms/Button";
import { Text } from "~src/designSystem/atoms/Text";
import { Tooltip } from "~src/designSystem/atoms/Tooltip";
import { View } from "~src/designSystem/atoms/View";
import { Flex } from "~src/designSystem/layout/Flex";
import { Spacer } from "~src/designSystem/layout/Spacer";
import { LoadingDots } from "~src/designSystem/loading/LoadingDots";
import { styled, t } from "~src/designSystem/theme";
import { calculateBidPrice } from "~src/shared/helpers";
import { formatBidPrice, formatCentsStr } from "~src/shared/helpers/format";
import { ContactSupport } from "~src/shared/inbox/ContactSupport";
import { ICountryCode } from "~src/shared/types";
import {
  vendorAcknowledgeApprovedForTrade,
  VendorAcknowledgeApprovedForTradeImpersonating,
} from "~src/vendor/apigen/acknowledgeApprovedForTrade";
import { useClientAckedApproval } from "~src/vendor/inbox/utils/computeInboxState";
import { useSupportedCurrency } from "~src/vendor/utils/useSupportedCurrencies";

import { useApprovedVendorMetricsQuery } from "./__generated__";
import { useVendorApprovedLottieStore } from "./lottie/store";

type IProps = {
  country?: ICountryCode;
};

export const Approved: React.FC<IProps> = ({ country }) => {
  const { data, loading } = useApprovedVendorMetricsQuery();
  const [submitting, setSubmitting] = useState<boolean>(false);

  const router = useRouter();
  const currency = useSupportedCurrency();

  const setIsPlaying = useVendorApprovedLottieStore((s) => s.setIsPlaying);
  const setAckedApproval = useClientAckedApproval((s) => s.setAckedApproval);

  React.useEffect(() => {
    // Start the Lottie confetti animation on mount
    setIsPlaying(true);
    return () => {
      // Cleanup on unmount
      setIsPlaying(false);
    };
  }, [setIsPlaying]);

  const onClickReadyToTrade = async () => {
    setSubmitting(true);

    try {
      await vendorAcknowledgeApprovedForTrade();
      // Reload the page so the vendor sees the trade view
      router.reload();
    } catch (error) {
      // If we're impersonating, manually set this state client side.
      if (error instanceof VendorAcknowledgeApprovedForTradeImpersonating) {
        setAckedApproval(true);
        return;
      }

      toast.error("Something went wrong!");
      throw error;
    }
  };

  const vendor = data?.vendors[0];
  const termLength = vendor?.max_contract_term_months;
  const totalARR = vendor?.vendor_metrics[0]?.total_addressable_arr;
  const tradeLimit = vendor?.credit_limit;

  const bidPrice = useMemo(() => {
    return calculateBidPrice(vendor?.rate_months_1, termLength);
  }, [vendor, termLength]);

  const metrics = useMemo((): IMetric[] => {
    return [
      {
        label:
          tradeLimit != null
            ? `${formatCentsStr(tradeLimit, currency, {
                siSuffix: true,
              }).toUpperCase()} `
            : "N/A",
        description: "Trading Limit",
        tooltipContentText:
          "Your trading limit is the maximum amount of recurring revenue you are currently able to trade on Pipe.",
      },
      {
        label: `${formatBidPrice(bidPrice, currency).toUpperCase()}`,
        description: "Bid Price",
        tooltipContentText:
          "The bid price is how much investors are willing to pay for your recurring revenues.",
      },
      {
        label: termLength != null ? `${termLength} mo` : "N/A",
        description: "Max Term Length",
        tooltipContentText:
          "The maximum duration of the contracts you are currently able to trade on Pipe. ",
      },
      {
        label:
          totalARR != null
            ? `${formatCentsStr(totalARR, currency, {
                siSuffix: true,
              }).toUpperCase()}`
            : "N/A",
        description: "ARR",
        tooltipContentText:
          "The portfolio of tradable customer contracts you’ve synced on the Pipe platform",
      },
    ];
  }, [tradeLimit, bidPrice, termLength, totalARR, currency]);

  return (
    <View
      pl="4"
      mt={{ "@initial": "20", "@lg": "6", "@md": "1" }}
      css={{
        // TODO(gt): This is to override VendorLayout padding. This should be handled
        // at the layout level but we gotta do what we gotta do now.
        "@media (max-width: 672px)": {
          "margin-left": "-48px",
          "margin-right": "-48px",
        },
      }}
    >
      <Flex
        justify="center"
        wrap
        gap={{
          "@initial": "20",
          "@lg": "8",
          "@md": "2",
        }}
      >
        <View width={{ "@initial": "104", "@lg": "96" }}>
          <Flex maxWidth="96" direction="column">
            <Text as="h1" text="titleSM">
              Congratulations!
            </Text>
            <Spacer y="6" />
            <Text as="p" color="contentSecondary">
              You’re now approved to trade up to{" "}
              {tradeLimit != null
                ? `${formatCentsStr(tradeLimit, currency, {
                    siSuffix: true,
                  }).toUpperCase()} `
                : "N/A"}{" "}
              on Pipe. On the next screen, you’ll be able to select which contracts you’d like to
              sell for an instant payout.
            </Text>
            <Spacer y="6" />
            <ContactSupport questionText="Need help?" country={country} />
          </Flex>
        </View>
        <View width={{ "@initial": "104", "@lg": "96" }}>
          <Flex direction="column" justify="start">
            <Flex align="center" gap="4" wrap>
              {metrics.map(({ tooltipContentText, label, description }, idx) => {
                return (
                  <Metric
                    key={`${label}-${idx}`}
                    tooltipContentText={tooltipContentText}
                    label={label}
                    description={description}
                    loading={loading}
                  />
                );
              })}
            </Flex>
            <Spacer y="10" />
            <View>
              <Button css={{ width: "128px" }} loading={submitting} onClick={onClickReadyToTrade}>
                Take me to Trade
              </Button>
            </View>
          </Flex>
        </View>
      </Flex>
    </View>
  );
};

type IMetric = {
  label: string;
  capitalizeLabel?: boolean;
  description: string;
  tooltipContentText: string;
  loading?: boolean;
};

const MetricContainer = styled(Flex, {
  borderStyle: t.borderStyles.solid,
  borderWidth: t.borderWidths[1],
  borderRadius: t.radii[8],
  borderColor: "$strokeElevate",
});

const Metric: React.FC<IMetric> = ({ label, description, tooltipContentText, loading }) => {
  return (
    <MetricContainer
      width={{ "@initial": "48", "@lg": "40", "@sm": "40", "@xs": "36" }}
      justify="center"
      direction="column"
      align="center"
      gap="4"
      p="4"
    >
      {loading !== true ? (
        <Text text="titleLG">{label}</Text>
      ) : (
        <View height="3">
          <LoadingDots />
        </View>
      )}
      <Tooltip
        borderRadius={t.radii[8].toString()}
        bgColor={t.colors.surfaceRaise.toString()}
        tooltipContent={<Text color="contentSecondary">{tooltipContentText}</Text>}
      >
        <Text
          color="contentSecondary"
          text="caption"
          css={{
            "text-decoration": "underline dashed",
            "text-underline-offset": "2px",
          }}
        >
          {description}
        </Text>
      </Tooltip>
    </MetricContainer>
  );
};

graphql`
  query ApprovedVendorMetricsQuery($public_id: String) {
    vendors(where: { public_id: { _eq: $public_id } }) {
      public_id
      credit_limit
      rate_months_1
      max_contract_term_months
      vendor_metrics(order_by: { timestamp: desc }, limit: 1) {
        total_addressable_arr
      }
    }
  }
`;
