import { useRouter } from "next/router";
import { useEffect } from "react";

enum QueryStringDataSourceType {
  Accounting = "accounting",
  Bank = "bank",
  Revenue = "revenue",
}

type IUseOpenAddDataSourceFromQueryStringArgs = {
  openBank: () => void;
  openAccounting: () => void;
  openBilling: () => void;
};

/**
 * Hook that automatically opens individual add DS flows depending on the
 * query string, e.g:
 * .../inbox?addDataSource=bank will open the add Plaid flow
 * .../inbox?addDataSource=revenue will open the add billing manager flow
 * .../inbox?addDataSource=accounting will open the add accounting flow
 *
 * Hook can be dropped on any page. Currently it's only used on the
 * /inbox route on the ConnectDataPage.
 *
 * @param takes fns that open individual DS flows
 */
export const useOpenAddDataSourceFromQueryString = ({
  openBank,
  openAccounting,
  openBilling,
}: IUseOpenAddDataSourceFromQueryStringArgs): void => {
  const router = useRouter();
  const { query } = router;

  //
  useEffect(() => {
    if (query.addDataSource === QueryStringDataSourceType.Revenue) {
      openBilling();
      return;
    }
    if (query.addDataSource === QueryStringDataSourceType.Bank) {
      openBank();
      return;
    }
    if (query.addDataSource === QueryStringDataSourceType.Accounting) {
      openAccounting();
    }
  }, [query.addDataSource, openBank, openBilling, openAccounting]);
};
