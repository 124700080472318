import React from "react";

import { OldCompanyLogoDontUse } from "~src/designSystem/deprecated/CompanyLogo";
import { PleaseGoUseFlexInstead } from "~src/designSystem/deprecated/Flexbox";

import { BankAccountInfo_account } from "./__generated__/BankAccountInfo";

interface IProps {
  account: BankAccountInfo_account;
}

export const BankAccountInfo = ({ account }: IProps): JSX.Element => {
  const item = account.plaid_item;
  return (
    <PleaseGoUseFlexInstead row align-items="center">
      {item !== null && (
        <OldCompanyLogoDontUse
          name={item.institution_name}
          logo={`data:image/png;base64,${item.institution_logo}`}
        />
      )}
      <PleaseGoUseFlexInstead marginHalfLeft row alignItems="center">
        {account.name} ****{account.mask}
      </PleaseGoUseFlexInstead>
    </PleaseGoUseFlexInstead>
  );
};

graphql`
  fragment BankAccountInfo_account on accounts {
    public_id
    mask
    name
    plaid_item {
      public_id
      institution_name
      institution_logo
    }
  }
`;
