import React, { useCallback, useMemo } from "react";

import { Table } from "~src/designSystem/tables/Table/components/Table";
import { useArrayAsTableData } from "~src/designSystem/tables/Table/hooks/useArrayAsTableData";
import { isNotUndefined } from "~src/shared/helpers/booleanCoercion";
import { useNotNullContext } from "~src/shared/helpers/useNotNullContext";
import { transformTableColumns } from "~src/shared/lists/utils/config";

import { ProposalsContext } from "../../contexts/ProposalsContext";

export const TradeReviewTable: React.FC = () => {
  const { data: proposalsData, checkable, config } = useNotNullContext(ProposalsContext);

  const selectedRowData = useMemo(() => {
    return checkable
      .getSelected()
      .map((id) => proposalsData.getRowByID(id)?.data)
      .filter(isNotUndefined);
  }, [checkable, proposalsData]);

  const keyGenerator = useCallback((index: number) => `${index}`, []);
  // The 8 is arbitrary--this table should not be in a loading state.
  const data = useArrayAsTableData(selectedRowData, keyGenerator, false, 8);

  const columns_config = useMemo(() => transformTableColumns(config), [config]);

  return <Table disableSort data={data} columns={columns_config} title="Selected Contracts" />;
};
