import * as Types from '../../../../__generated__/graphql/types';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type CSVManagerQueryVariables = Types.Exact<{
  dataSourceID: Types.Scalars['String'];
  dataType: Types.uploaded_vendor_data_type_enum_enum;
}>;


export type CSVManagerQuery = { readonly __typename?: 'query_root', readonly data_sources: ReadonlyArray<{ readonly __typename?: 'data_sources', readonly manual_data_source: { readonly __typename?: 'manual_data_sources', readonly label: string, readonly uploaded_vendor_data: ReadonlyArray<{ readonly __typename?: 'uploaded_vendor_data', readonly public_id: string, readonly created_at: string, readonly model: Types.uploaded_vendor_data_source_model_enum_enum | null, readonly status: Types.uploaded_vendor_data_source_status_enum_enum | null, readonly error_reason: string | null, readonly resource_slug: string }>, readonly uploaded_vendor_data_aggregate: { readonly __typename?: 'uploaded_vendor_data_aggregate', readonly aggregate: { readonly __typename?: 'uploaded_vendor_data_aggregate_fields', readonly count: number } | null } } | null }> };


export const CSVManagerQueryDocument = gql`
    query CSVManagerQuery($dataSourceID: String!, $dataType: uploaded_vendor_data_type_enum_enum!) {
  data_sources(where: {public_id: {_eq: $dataSourceID}}) {
    manual_data_source {
      label
      uploaded_vendor_data(
        where: {data_type: {_eq: $dataType}}
        order_by: {created_at: desc}
      ) {
        public_id
        created_at
        model
        status
        error_reason
        resource_slug
      }
      uploaded_vendor_data_aggregate {
        aggregate {
          count
        }
      }
    }
  }
}
    `;

/**
 * __useCSVManagerQuery__
 *
 * To run a query within a React component, call `useCSVManagerQuery` and pass it any options that fit your needs.
 * When your component renders, `useCSVManagerQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useCSVManagerQuery({
 *   variables: {
 *      dataSourceID: // value for 'dataSourceID'
 *      dataType: // value for 'dataType'
 *   },
 * });
 */
export function useCSVManagerQuery(baseOptions: Apollo.QueryHookOptions<CSVManagerQuery, CSVManagerQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<CSVManagerQuery, CSVManagerQueryVariables>(CSVManagerQueryDocument, options);
      }
export function useCSVManagerQueryLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<CSVManagerQuery, CSVManagerQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<CSVManagerQuery, CSVManagerQueryVariables>(CSVManagerQueryDocument, options);
        }
export type CSVManagerQueryHookResult = ReturnType<typeof useCSVManagerQuery>;
export type CSVManagerQueryLazyQueryHookResult = ReturnType<typeof useCSVManagerQueryLazyQuery>;
export type CSVManagerQueryQueryResult = Apollo.QueryResult<CSVManagerQuery, CSVManagerQueryVariables>;