import React from "react";

import { useNotNullContext } from "~src/shared/helpers/useNotNullContext";
import { List } from "~src/shared/lists/components/List";
import { IListModel } from "~src/shared/lists/types/models";

import { ProposalsContext } from "../../contexts/ProposalsContext";

export const TradeSelectTable: React.FC = () => {
  const { data, setConfig, checkable } = useNotNullContext(ProposalsContext);

  return (
    <List<IListModel.lists_tradeable_contracts>
      data={data}
      setConfig={setConfig}
      checkable={checkable}
      title="Contracts to Trade"
    />
  );
};
