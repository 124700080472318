/* eslint-disable react/style-prop-object */
import React from "react";

import { t } from "~src/designSystem/theme";

export type IProps = {
  fill?: string;
};
export const AnalyticsIcon: React.FC<IProps> = ({ fill }) => (
  <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M21.5999 21.3H2.3999V19.5H21.5999V21.3Z"
      fill={fill ?? `${t.colors.strokeElevate}`}
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M8.4001 12V18H3.6001V12H8.4001Z"
      fill={fill ?? `${t.colors.strokeElevate}`}
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M14.4001 7.2002V18.0002H9.6001V7.2002H14.4001Z"
      fill={fill ?? `${t.colors.strokeElevate}`}
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M20.4001 2.40039V18.0004H15.6001V2.40039H20.4001Z"
      fill={fill ?? `${t.colors.strokeElevate}`}
    />
  </svg>
);
