import React from "react";

import { useOpenAddDataSourceFromQueryString } from "~src/shared/dataSources/useOpenAddDataSourceFromQueryString";
import { IRequirement } from "~src/shared/dataSourcesRequirements/hooks/useFetchDataSourceRequirements";
import { ErrorBoundary } from "~src/shared/errors/components/ErrorBoundary";
import { ErrorComponent } from "~src/shared/errors/components/ErrorComponent";
import { VendorLayout } from "~src/vendor/layout";

import { ConnectDataSources } from "../components/ConnectDataSources";

type IProps = {
  requirements: IRequirement[];
  openBank: () => void;
  openAccounting: () => void;
  openBilling: () => void;
  reconnectOnSuccess: () => Promise<void>;
};

export const ConnectDataPage: React.FC<IProps> = (props) => {
  const { openBilling, openAccounting, openBank } = props;

  // Open individual DS UX flows automatically if the url is:
  // .../inbox?addDataSource=bank|accounting|revenue
  useOpenAddDataSourceFromQueryString({ openBilling, openBank, openAccounting });

  return (
    <VendorLayout>
      <ErrorBoundary fallback={<ErrorComponent />}>
        <ConnectDataSources {...props} />
      </ErrorBoundary>
    </VendorLayout>
  );
};
