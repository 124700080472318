import styled from "@emotion/styled";
import React from "react";

import { Text } from "~src/designSystem/atoms/Text";
import { PleaseGoUseFlexInstead } from "~src/designSystem/deprecated/Flexbox";
import { t } from "~src/designSystem/theme";

export type IConnectionStatusBadgeVariant = "neutral" | "warning";

export type IConnectionBadgeProps = {
  label: string;
  variant?: IConnectionStatusBadgeVariant;
  withIcon?: boolean;
};

export const ConnectionStatusBadge: React.FC<IConnectionBadgeProps> = ({
  variant = "neutral",
  label,
  withIcon = false,
}) => {
  return (
    <Wrapper variant={variant}>
      {withIcon && (
        <span style={{ marginLeft: "-2px" }}>
          <LoadingIcon />
        </span>
      )}
      <Label
        text="caption"
        color={variant === "warning" ? "statusWarningContent" : "contentSecondary"}
      >
        {label}
      </Label>
    </Wrapper>
  );
};

const Wrapper = styled(PleaseGoUseFlexInstead)<{ variant: IConnectionStatusBadgeVariant }>`
  background: ${(props) =>
    props.variant === "warning"
      ? t.colors.statusWarningSurface.toString()
      : t.colors.surfaceRaise.toString()};
  border-radius: 20px;
  flex-direction: row;
  gap: ${t.space[1].toString()};
  padding: ${t.c.spacing("1")} 6px;
  height: 24px;
  width: fit-content;
`;

const Label = styled(Text)`
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
`;

const LoadingIcon: React.FC = () => (
  <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
    <circle cx="8" cy="8" r="5.25" stroke="#2F3337" strokeWidth="1.5" />
    <path
      d="M13.25 8C13.25 8.82838 13.054 9.64501 12.678 10.3831C12.3019 11.1212 11.7566 11.7599 11.0865 12.2469C10.4164 12.7339 9.64052 13.0554 8.82236 13.1852C8.00421 13.315 7.16696 13.2493 6.37905 12.9935C5.59114 12.7377 4.87494 12.2992 4.28898 11.7136C3.70302 11.1281 3.26394 10.4122 3.00763 9.62442C2.75132 8.83669 2.68505 7.99949 2.81424 7.18125C2.94343 6.363 3.26441 5.58694 3.75094 4.91649"
      stroke="#868F98"
      strokeWidth="1.5"
    />
  </svg>
);
