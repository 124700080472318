import styled from "@emotion/styled";
import React, { useMemo } from "react";

import { PleaseGoUseFlexInstead } from "~src/designSystem/deprecated/Flexbox";
import { useUser } from "~src/shared/auth/useUser";
import { IDataSource, useVendorDataSources } from "~src/shared/dataSources/useVendorDataSources";
import { IRequirement } from "~src/shared/dataSourcesRequirements/hooks/useFetchDataSourceRequirements";
import { pluralizer } from "~src/shared/helpers";
import { TABLET_MAX } from "~src/shared/styles/responsive";

import { ConnectDataSourceCard } from "./ConnectDataSourceCard";
import { ConnectYourData } from "./ConnectYourData";
import { dataSourceRows } from "./dataSourceRows";
import { LegalEntityCard } from "./LegalEntityCard";

type IProps = {
  requirements: IRequirement[];
  openBank: () => void;
  openAccounting: () => void;
  openBilling: () => void;
  reconnectOnSuccess: () => Promise<void>;
};

export const ConnectDataSources: React.FC<IProps> = (props) => {
  const { country } = useUser().vendor;
  return (
    <ConnectDataSourcesWrapper gap="80px" justifyContent="center">
      <ConnectYourDataWrapper marginX="16px">
        <ConnectYourData country={country ?? undefined} />
      </ConnectYourDataWrapper>
      <DataSourceCards {...props} />
    </ConnectDataSourcesWrapper>
  );
};

export const DataSourceCards: React.FC<IProps> = (props) => {
  const { requirements, openBank, openAccounting, openBilling, reconnectOnSuccess } = props;
  const { data } = useVendorDataSources();

  const dataSources = useMemo(() => {
    return data.dataSources.reduce(
      (sourcesMap, ds) => {
        if (ds.isAccounting) {
          sourcesMap.accounting.push(ds);
        } else if (ds.isBank) {
          sourcesMap.bank.push(ds);
        } else if (ds.isRevenue) {
          sourcesMap.billing.push(ds);
        }
        return sourcesMap;
      },
      {
        accounting: [] as IDataSource[],
        bank: [] as IDataSource[],
        billing: [] as IDataSource[],
      },
    );
  }, [data.dataSources]);

  // All connected data sources by type, including the disconnected ones.
  const numConnectedDataSources = {
    accounting: dataSources.accounting.length,
    bank: dataSources.bank.length,
    billing: dataSources.billing.length,
  };

  // Initial data sources
  const numInitialDataSources = requirements
    .filter((req) => req.type === "initial")
    .reduce(
      (numSourcesMap, req) => {
        if (req.category === "accounting") {
          numSourcesMap.accounting += 1;
        } else if (req.category === "bank") {
          numSourcesMap.bank += 1;
          // eslint-disable-next-line @typescript-eslint/no-unnecessary-condition
        } else if (req.category === "revenue") {
          numSourcesMap.billing += 1;
        }
        return numSourcesMap;
      },
      { accounting: 0, bank: 0, billing: 0 },
    );

  // Additional data sources
  const numAdditionalDataSources = requirements
    .filter((req) => req.type === "additional")
    .reduce(
      (numSourcesMap, req) => {
        if (req.category === "accounting") {
          numSourcesMap.accounting += 1;
        } else if (req.category === "bank") {
          numSourcesMap.bank += 1;
          // eslint-disable-next-line @typescript-eslint/no-unnecessary-condition
        } else if (req.category === "revenue") {
          numSourcesMap.billing += 1;
        }
        return numSourcesMap;
      },
      { accounting: 0, bank: 0, billing: 0 },
    );

  // Disconnected data sources
  const numDisconnectedDataSources = data.dataSources
    .filter((ds) => ds.userActionRequiredAt != null)
    .reduce(
      (numSourcesMap, ds) => {
        if (ds.isAccounting) {
          numSourcesMap.accounting += 1;
        } else if (ds.isBank) {
          numSourcesMap.bank += 1;
        } else if (ds.isRevenue) {
          numSourcesMap.billing += 1;
        }
        return numSourcesMap;
      },
      { accounting: 0, bank: 0, billing: 0 },
    );

  // Disconnected && additional data sources
  const numNeedAttentionDataSources = {
    accounting: numDisconnectedDataSources.accounting + numAdditionalDataSources.accounting,
    bank: numDisconnectedDataSources.bank + numAdditionalDataSources.bank,
    billing: numDisconnectedDataSources.billing + numAdditionalDataSources.billing,
  };

  const {
    accounting: accountingAccordionBody,
    bank: bankAccordionBody,
    billing: billingAccordionBody,
  } = dataSourceRows({
    dataSources,
    numAdditionalDataSources,
    handleReconnect: reconnectOnSuccess,
    openBank,
    openAccounting,
    openBilling,
  });

  // There are always 3 cards: bank, billing, accounting.
  const DATA_SOURCE_CARDS = [
    {
      headingText: "Connect your revenue",
      bodyText:
        "This allows us to automatically identify your recurring revenue and sync it with Pipe.",
      addSourceAction: openBilling,
      addSourceButtonText: numConnectedDataSources.billing === 0 ? "Add source" : "Add another",
      needsInitialLink: numInitialDataSources.billing > 0,
      needsAdditionalLink: numAdditionalDataSources.billing > 0,
      needsRelink: numDisconnectedDataSources.billing > 0,
      withConnectedBadgeIcon:
        numConnectedDataSources.billing > 0 &&
        numConnectedDataSources.billing <
          numConnectedDataSources.billing +
            numDisconnectedDataSources.billing +
            numAdditionalDataSources.billing,
      connectedBadgeLabel: `${numConnectedDataSources.billing}/${
        numConnectedDataSources.billing +
        numAdditionalDataSources.billing +
        numInitialDataSources.billing
      } added`,
      requiresAttentionBadgeLabel:
        numNeedAttentionDataSources.billing > 0
          ? `${pluralizer(numNeedAttentionDataSources.billing, "requires", "require")} attention`
          : undefined,
      accordionBody: billingAccordionBody,
    },
    {
      headingText: "Connect your bank accounts",
      bodyText: "This allows us to calculate your estimated burn rate and runway.",
      addSourceAction: openBank,
      addSourceButtonText: numConnectedDataSources.bank === 0 ? "Add source" : "Add another",
      needsInitialLink: numInitialDataSources.bank > 0,
      needsAdditionalLink: numAdditionalDataSources.bank > 0,
      needsRelink: numDisconnectedDataSources.bank > 0,
      withConnectedBadgeIcon:
        numConnectedDataSources.bank > 0 &&
        numConnectedDataSources.bank <
          numConnectedDataSources.bank +
            numDisconnectedDataSources.bank +
            numAdditionalDataSources.bank,
      connectedBadgeLabel: `${numConnectedDataSources.bank}/${
        numConnectedDataSources.bank + numAdditionalDataSources.bank + numInitialDataSources.bank
      } added`,
      requiresAttentionBadgeLabel:
        numNeedAttentionDataSources.bank > 0
          ? `${pluralizer(numNeedAttentionDataSources.bank, "requires", "require")} attention`
          : undefined,
      accordionBody: bankAccordionBody,
    },

    {
      headingText: "Connect your accounting",
      bodyText: "This allows us to evaluate your financial health and determine a risk score.",
      addSourceAction: openAccounting,
      addSourceButtonText: numConnectedDataSources.accounting === 0 ? "Add source" : "Add another",
      needsInitialLink: numInitialDataSources.accounting > 0,
      needsAdditionalLink: numAdditionalDataSources.accounting > 0,
      needsRelink: numDisconnectedDataSources.accounting > 0,
      withConnectedBadgeIcon:
        numConnectedDataSources.accounting > 0 &&
        numConnectedDataSources.accounting <
          numConnectedDataSources.accounting +
            numDisconnectedDataSources.accounting +
            numAdditionalDataSources.accounting,
      connectedBadgeLabel: `${numConnectedDataSources.accounting}/${
        numConnectedDataSources.accounting +
        numAdditionalDataSources.accounting +
        numInitialDataSources.accounting
      } added`,
      requiresAttentionBadgeLabel:
        numNeedAttentionDataSources.accounting > 0
          ? `${pluralizer(numNeedAttentionDataSources.accounting, "requires", "require")} attention`
          : undefined,
      accordionBody: accountingAccordionBody,
    },
  ];

  return (
    <PleaseGoUseFlexInstead gap="16px" alignItems="center">
      {DATA_SOURCE_CARDS.map((cardProps, idx) => {
        return <ConnectDataSourceCard key={`${idx}`} {...cardProps} />;
      })}
      <LegalEntityCard />
    </PleaseGoUseFlexInstead>
  );
};

const ConnectDataSourcesWrapper = styled(PleaseGoUseFlexInstead)`
  flex-direction: row;
  padding: 32px;
  @media (max-width: ${TABLET_MAX}) {
    flex-direction: column;
    align-items: center;
    padding: 64px 0px 80px;
    // override padding from parent div
    margin-left: -48px;
    margin-right: -48px;
  }
`;

const ConnectYourDataWrapper = styled(PleaseGoUseFlexInstead)`
  max-width: 400px;
  @media (max-width: ${TABLET_MAX}) {
    max-width: 480px;
  }
`;
