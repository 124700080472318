/** @jsxImportSource @emotion/react */
import { css, useTheme } from "@emotion/react";
import styled from "@emotion/styled";
import copy from "copy-to-clipboard";
import React from "react";
import toast from "react-hot-toast";
import { MdContentCopy } from "react-icons/md";

import { adminRequests } from "~src/admin/requests";
import { Button } from "~src/designSystem/atoms/Button";
import { Link } from "~src/designSystem/atoms/Link";
import { Text } from "~src/designSystem/atoms/Text";
import { PleaseGoUseFlexInstead } from "~src/designSystem/deprecated/Flexbox";
import { Gridbox } from "~src/designSystem/layout/Gridbox";
import { Spacer } from "~src/designSystem/layout/Spacer";
import { ImageOrSVG } from "~src/designSystem/sortLater/ImageOrSVG";
import { t } from "~src/designSystem/theme";
import { UploadedVendorDataSourceMap } from "~src/shared/constants";
import { INTEGRATION_TYPE_TO_UPLOADED_VENDOR_DATA_TYPE_MAP } from "~src/shared/dataSources/utils";
import { IFeatureFlagName, useFeatureFlag } from "~src/shared/featureFlags";
import { Format } from "~src/shared/formatters";
import { pluralizer } from "~src/shared/helpers";
import { dateTimeWithTimezoneFmt } from "~src/shared/helpers/formatDate";
import { useModal } from "~src/shared/modals/modal-context";
import { callRequest } from "~src/shared/requests/useRequest";
import { useStepper } from "~src/shared/stepper/stepperContext";
import {
  IConnectDataSourceFlowSource,
  IIntegrationType,
  IUploadedVendorDataTypeEnum,
} from "~src/shared/types";
import { vendorRequests } from "~src/vendor/requests";

import { ContactSupport } from "../components/ContactSupport";
import { DataSourceLayout } from "../components/dataSourceLayout";
import { useOnDeleteDataSource } from "../useOnDeleteDataSource";
import { useCSVManagerQuery } from "./__generated__/CSVManager";
import { CSVLogo } from "./CSVLogo";
import { CSVUploader } from "./CSVUploader";
import { ManualDataSourceStatusBadge } from "./ManualDataSourceStatusBadge";

interface IProps {
  type: IIntegrationType;
  dataSourceID: string;
  vendorID?: string;
  isAdminRequest?: boolean;
  canAddIntegration: boolean;
  refetchDataSources: () => Promise<void>;
  source: IConnectDataSourceFlowSource;
}

export const CSVManager: React.FC<IProps> = ({
  type,
  dataSourceID,
  isAdminRequest = false,
  vendorID,
  canAddIntegration,
  refetchDataSources,
  source,
}: IProps) => {
  const theme = useTheme();
  const { clearStackAndCloseModal, removeTopOfStack } = useModal();
  const { addAndOpenStepperDialog } = useStepper();
  const { data } = useCSVManagerQuery({
    variables: {
      dataSourceID,
      dataType:
        IUploadedVendorDataTypeEnum[INTEGRATION_TYPE_TO_UPLOADED_VENDOR_DATA_TYPE_MAP[type]],
    },
  });
  const onDelete = useOnDeleteDataSource({ refetchDataSources });
  const manualDataSource = data?.data_sources[0]?.manual_data_source;
  const uploadedVendorData = manualDataSource?.uploaded_vendor_data ?? [];

  const integrationLabel = manualDataSource?.label;

  const connectAPIFlag = useFeatureFlag(IFeatureFlagName.CONNECT_API);

  return (
    <>
      <DataSourceLayout
        heading={`${integrationLabel} (Manual)`}
        description={
          <>
            {connectAPIFlag && (
              <CopyDataSourceWrapper
                href=""
                onClick={() => {
                  copy(dataSourceID);
                  toast("Data source ID copied to clipboard.");
                }}
              >
                <MdContentCopy />
                Copy data source ID
              </CopyDataSourceWrapper>
            )}
            <>
              {isAdminRequest && (
                <DeleteWrapper>
                  <Button
                    kind="danger"
                    size="small"
                    onClick={() => {
                      clearStackAndCloseModal();
                      onDelete(dataSourceID);
                    }}
                  >
                    Delete Datasource
                  </Button>
                </DeleteWrapper>
              )}
            </>
          </>
        }
      >
        <Gridbox
          marginBottom="4px"
          marginTop="32px"
          alignItems="center"
          fullWidth
          gridTemplateColumns="1fr 1fr"
        >
          <Text color="contentSecondary">{`Uploaded ${pluralizer(
            manualDataSource?.uploaded_vendor_data_aggregate.aggregate?.count ?? 0,
            "CSV",
            "CSVs",
          )}`}</Text>
          {canAddIntegration && (
            <Link
              css={css`
                text-align: right;
              `}
              href=""
              onClick={() => {
                removeTopOfStack();
                addAndOpenStepperDialog({
                  component: (
                    <CSVUploader
                      onSuccess={refetchDataSources}
                      isAdminRequest={isAdminRequest}
                      isLabelEditable={false}
                      label={integrationLabel}
                      source={source}
                      vendorID={vendorID}
                    />
                  ),
                  config: {
                    title: "Upload your revenue streams",
                  },
                });
              }}
            >
              + Upload CSV
            </Link>
          )}
        </Gridbox>
        <Gridbox
          maxHeight="275px"
          overflow="auto"
          gap="16px"
          padding="16px"
          borderRadius="8px"
          gridTemplateColumns="auto 1fr auto"
          alignItems="center"
          width="100%"
          css={css`
            border: 1px solid ${theme.components.CSVManager.FilesWrapper.border.color};
          `}
        >
          {uploadedVendorData.length > 0 ? (
            uploadedVendorData.map(({ created_at, model, status, error_reason, resource_slug }) => {
              return (
                <React.Fragment key={`${resource_slug}`}>
                  <CSVIconWrapper>
                    <ImageOrSVG src={CSVLogo} />
                  </CSVIconWrapper>
                  <PleaseGoUseFlexInstead overflow="auto">
                    <Gridbox
                      gap={t.space[4].toString()}
                      gridTemplateColumns="1fr auto"
                      alignItems="center"
                    >
                      <Link
                        href=""
                        onClick={async () => {
                          try {
                            const getFileDownloadURL = isAdminRequest
                              ? adminRequests.getFileDownloadURL
                              : vendorRequests.getFileDownloadURL;
                            const { data: respData } = await callRequest(
                              getFileDownloadURL({
                                fileName: decodeURIComponent(resource_slug),
                              }),
                            );
                            if (respData != null) {
                              window.open(respData.downloadURL, "_blank");
                            } else {
                              toast.error("Couldn't fetch file download URL.");
                            }
                          } catch (e) {
                            toast.error(e.message);
                          }
                        }}
                      >
                        {model != null ? UploadedVendorDataSourceMap[model] : "CSV"}
                      </Link>
                      <Text color="contentSecondary">
                        <Format.Date
                          date={created_at}
                          formatter={dateTimeWithTimezoneFmt}
                          noTooltip
                        />
                      </Text>
                    </Gridbox>
                    {error_reason != null && <Text color="contentBad">{error_reason}</Text>}
                  </PleaseGoUseFlexInstead>
                  <PleaseGoUseFlexInstead
                    alignSelf={error_reason != null ? "start" : "center"}
                    alignItems="center"
                  >
                    {status != null && <ManualDataSourceStatusBadge status={status} />}
                  </PleaseGoUseFlexInstead>
                </React.Fragment>
              );
            })
          ) : (
            <Text>No CSVs found</Text>
          )}
        </Gridbox>
        <Spacer y="6" />
        <ContactSupport integrationType="billing_manager" />
      </DataSourceLayout>
    </>
  );
};

const CopyDataSourceWrapper = styled(Link)`
  svg {
    margin-right: 4px;
  }
  display: flex;
  align-items: center;

  cursor: pointer;
`;

const DeleteWrapper = styled.div`
  margin-top: ${t.space[3].toString()};
`;

const CSVIconWrapper = styled.div`
  align-self: start;
`;

graphql`
  query CSVManagerQuery($dataSourceID: String!, $dataType: uploaded_vendor_data_type_enum_enum!) {
    data_sources(where: { public_id: { _eq: $dataSourceID } }) {
      manual_data_source {
        label
        uploaded_vendor_data(
          where: { data_type: { _eq: $dataType } }
          order_by: { created_at: desc }
        ) {
          public_id
          created_at
          model
          status
          error_reason
          resource_slug
        }
        uploaded_vendor_data_aggregate {
          aggregate {
            count
          }
        }
      }
    }
  }
`;
