import { useTheme } from "@emotion/react";
import styled from "@emotion/styled";
import { useFocusRing } from "@react-aria/focus";
import { useRadio, useRadioGroup } from "@react-aria/radio";
import { VisuallyHidden } from "@react-aria/visually-hidden";
import { RadioGroupState, useRadioGroupState } from "@react-stately/radio";
import React from "react";

import { t } from "~src/designSystem/theme";

const DEFAULT_RADIO_CONTEXT: RadioGroupState = {
  name: "ERROR_RADIO_CONTEXT_MISSING",
  isDisabled: false,
  isReadOnly: false,
  lastFocusedValue: null,
  selectedValue: null,
  setLastFocusedValue: () => {},
  setSelectedValue: () => {},
};

const RadioContext = React.createContext<RadioGroupState>(DEFAULT_RADIO_CONTEXT);

export interface IRadioGroupProps {
  headerComponent?: React.ReactElement;
  /** The current value (controlled). */
  value?: string;
  /** The default value (uncontrolled). */
  defaultValue?: string;
  /** Handler that is called when the value changes. */
  onChange?: (value: string) => void;
  formName?: string;
  isDisabled?: boolean;
}

export type RadioGroupHeaderImplicitlyAddedProps = React.HTMLAttributes<HTMLElement>;

const RadioGroup: React.FC<IRadioGroupProps> = (props) => {
  const state = useRadioGroupState(props);
  const { radioGroupProps, labelProps } = useRadioGroup(props, state);

  return (
    <div {...radioGroupProps}>
      {props.headerComponent !== undefined
        ? React.cloneElement(props.headerComponent, labelProps)
        : null}
      <RadioContext.Provider value={state}>{props.children}</RadioContext.Provider>
    </div>
  );
};

export interface IRadioProps {
  className?: string;
  value: string;
  children: React.ReactElement;
  backgroundColor?: string;
  selectedBackgroundColor?: string;
}

interface IRadioContainer {
  isSelected: boolean;
  isDisabled?: boolean;
  backgroundColor?: string;
  selectedBackgroundColor?: string;
}

export const RadioContainer = styled.label<IRadioContainer>`
  display: flex;
  align-items: center;
  min-height: 56px;
  max-height: 56px;
  padding: ${t.c.spacing("2", "5")};
  background: ${(props) => props.theme.components.Radio.rowBgColor};
  transition: ${(props) => props.theme.transition};
  &:hover {
    cursor: pointer;
    background: ${(props) => props.theme.components.Radio.rowBgColorHover};
  }
  &:hover .radio-primary-color-on-hover {
    color: ${(props) => props.theme.components.Radio.primaryTextColor};
  }
  &:hover circle {
    stroke: ${(props) =>
      props.isSelected
        ? props.theme.components.Radio.circleOuterColorSelectedHover
        : props.theme.components.Radio.circleOuterColorHover};
  }

  background: ${(props) =>
    props.isSelected && props.selectedBackgroundColor != null
      ? props.selectedBackgroundColor
      : props.backgroundColor != null
      ? props.backgroundColor
      : props.theme.components.Radio.rowBgColor};

  ${(props) => {
    if (props.isDisabled != null && !props.isDisabled) {
      return `

      &:hover {
        cursor: pointer;
        background: ${() => props.theme.components.Radio.rowBgColorHover};
      }
      &:hover .radio-primary-color-on-hover {
        color: ${() => props.theme.components.Radio.primaryTextColor};
      }
      `;
    }
  }}
`;

const RadioCircle = styled.circle`
  transition: all 0.25s ease-in-out;
`;

const Radio: React.FC<IRadioProps> = (props) => {
  const state = React.useContext(RadioContext);
  const ref = React.useRef(null);
  const { inputProps } = useRadio(props, state, ref);
  const { focusProps } = useFocusRing();

  const theme = useTheme();
  const isSelected = state.selectedValue === props.value;
  const strokeWidth = isSelected ? 4 : 1.25; // Radii from Figma

  return (
    <RadioContainer
      className={props.className}
      isSelected={isSelected}
      isDisabled={state.isDisabled}
      backgroundColor={props.backgroundColor}
      selectedBackgroundColor={props.selectedBackgroundColor}
    >
      <VisuallyHidden>
        <input {...inputProps} {...focusProps} ref={ref} />
      </VisuallyHidden>
      <svg width={16} height={16} aria-hidden="true" style={{ marginRight: 12 }}>
        <RadioCircle
          cx={8}
          cy={8}
          r={8 - strokeWidth / 2}
          fill={
            isSelected
              ? theme.components.Radio.circleInnerColorSelected
              : theme.components.Radio.circleInnerColor
          }
          stroke={
            isSelected
              ? theme.components.Radio.circleOuterColorSelected
              : theme.components.Radio.circleOuterColor
          }
          strokeWidth={strokeWidth}
        />
      </svg>
      {React.cloneElement(props.children, {
        isSelected,
      })}
    </RadioContainer>
  );
};

export { Radio, RadioGroup };
