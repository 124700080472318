import * as Types from '../../../../../__generated__/graphql/types';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type CoreMetricsQueryByPublicIDVariables = Types.Exact<{
  public_id: Types.InputMaybe<Types.Scalars['String']>;
}>;


export type CoreMetricsQueryByPublicID = { readonly __typename?: 'query_root', readonly vendors: ReadonlyArray<{ readonly __typename?: 'vendors', readonly public_id: string, readonly credit_limit: number, readonly rate_months_1: number, readonly max_contract_term_months: number, readonly payment_status: { readonly __typename?: 'vendor_payment_status', readonly credit_usage: number | null } | null, readonly vendor_metrics: ReadonlyArray<{ readonly __typename?: 'vendor_metrics', readonly total_addressable_arr: number | null }> }> };


export const CoreMetricsQueryByPublicIDDocument = gql`
    query CoreMetricsQueryByPublicID($public_id: String) {
  vendors(where: {public_id: {_eq: $public_id}}) {
    public_id
    credit_limit
    payment_status {
      credit_usage
    }
    rate_months_1
    max_contract_term_months
    vendor_metrics(order_by: {timestamp: desc}, limit: 1) {
      total_addressable_arr
    }
  }
}
    `;

/**
 * __useCoreMetricsQueryByPublicID__
 *
 * To run a query within a React component, call `useCoreMetricsQueryByPublicID` and pass it any options that fit your needs.
 * When your component renders, `useCoreMetricsQueryByPublicID` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useCoreMetricsQueryByPublicID({
 *   variables: {
 *      public_id: // value for 'public_id'
 *   },
 * });
 */
export function useCoreMetricsQueryByPublicID(baseOptions?: Apollo.QueryHookOptions<CoreMetricsQueryByPublicID, CoreMetricsQueryByPublicIDVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<CoreMetricsQueryByPublicID, CoreMetricsQueryByPublicIDVariables>(CoreMetricsQueryByPublicIDDocument, options);
      }
export function useCoreMetricsQueryByPublicIDLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<CoreMetricsQueryByPublicID, CoreMetricsQueryByPublicIDVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<CoreMetricsQueryByPublicID, CoreMetricsQueryByPublicIDVariables>(CoreMetricsQueryByPublicIDDocument, options);
        }
export type CoreMetricsQueryByPublicIDHookResult = ReturnType<typeof useCoreMetricsQueryByPublicID>;
export type CoreMetricsQueryByPublicIDLazyQueryHookResult = ReturnType<typeof useCoreMetricsQueryByPublicIDLazyQuery>;
export type CoreMetricsQueryByPublicIDQueryResult = Apollo.QueryResult<CoreMetricsQueryByPublicID, CoreMetricsQueryByPublicIDVariables>;