import styled from "@emotion/styled";
import React from "react";

import { Button } from "~src/designSystem/atoms/Button";
import { Text } from "~src/designSystem/atoms/Text";
import { TwoPartCard } from "~src/designSystem/atoms/TwoPartCard";
import { PleaseGoUseFlexInstead } from "~src/designSystem/deprecated/Flexbox";
import { Flex } from "~src/designSystem/layout/Flex";
import { t } from "~src/designSystem/theme";

import { ConnectionStatusBadge } from "../ConnectionStatusBadge";
import { CircleCheck } from "../icons/CircleCheck";
import { CirclePlus } from "../icons/CirclePlus";
import { CircleWarning } from "../icons/CircleWarning";
import { Accordion } from "./Accordion";

export type IConnectDataSourceCardProps = {
  headingText: string;
  bodyText: string;
  addSourceButtonText: string;
  needsInitialLink: boolean;
  needsAdditionalLink: boolean;
  needsRelink: boolean;
  addSourceAction: () => void;
  withConnectedBadgeIcon?: boolean;
  connectedBadgeLabel: string;
  requiresAttentionBadgeLabel?: string;
  accordionBody?: React.ReactNode;
};

const CARD_NO_RADIUS_BREAKPOINT = "425px";

export const ConnectDataSourceCard: React.FC<IConnectDataSourceCardProps> = (props) => {
  const {
    headingText,
    bodyText,
    addSourceAction,
    addSourceButtonText,
    withConnectedBadgeIcon,
    connectedBadgeLabel,
    requiresAttentionBadgeLabel,
    needsAdditionalLink,
    needsInitialLink,
    needsRelink,
    accordionBody,
  } = props;

  const statusIcon = (() => {
    if (needsAdditionalLink || needsRelink) {
      return <CircleWarning />;
    }
    if (needsInitialLink) {
      return <CirclePlus />;
    }
    return <CircleCheck />;
  })();

  const DataSourceStatusRow = (
    <PleaseGoUseFlexInstead row gap="6px" paddingX="16px" paddingY="8px" alignItems="center">
      <ConnectionStatusBadge label={connectedBadgeLabel} withIcon={withConnectedBadgeIcon} />
      {requiresAttentionBadgeLabel != null && (
        <ConnectionStatusBadge label={requiresAttentionBadgeLabel} variant="warning" />
      )}
    </PleaseGoUseFlexInstead>
  );

  return (
    <TwoPartCardWrapper justifyContent="space-between" maxWidth="480px">
      <PleaseGoUseFlexInstead
        row
        alignItems="flex-start"
        paddingTop="16px"
        paddingX="16px"
        paddingBottom="8px"
        marginY="8px"
        marginX="0px"
      >
        <PleaseGoUseFlexInstead marginRight="32px" marginTop="0px" gap="6px">
          <Flex>
            <span style={{ marginRight: "4px", marginLeft: "-2px", marginTop: "2px" }}>
              {statusIcon}
            </span>
            <Text color="contentEmphasis" text="paragraphStrong">
              {headingText}
            </Text>
          </Flex>
          <Text text="caption" color="contentSecondary" css={{ marginBottom: 0 }}>
            {bodyText}
          </Text>
        </PleaseGoUseFlexInstead>
        <PleaseGoUseFlexInstead shrink={0}>
          <Button
            size="small"
            kind={needsInitialLink || needsAdditionalLink ? "primary" : "secondary"}
            onClick={addSourceAction}
          >
            {addSourceButtonText}
          </Button>
        </PleaseGoUseFlexInstead>
      </PleaseGoUseFlexInstead>
      {accordionBody != null ? (
        <Accordion id={headingText.split(" ").join("-")}>
          {DataSourceStatusRow}
          <PleaseGoUseFlexInstead paddingX="20px" paddingY="16px" gap="12px">
            {accordionBody}
          </PleaseGoUseFlexInstead>
        </Accordion>
      ) : (
        DataSourceStatusRow
      )}
    </TwoPartCardWrapper>
  );
};

const TwoPartCardWrapper = styled(TwoPartCard)`
  @media (max-width: ${CARD_NO_RADIUS_BREAKPOINT}) {
    border-radius: 0;
  }
  // TODO: remove if/when the Card component has new DS styles?
  background-color: ${t.colors.surfaceElevate.toString()};
  border-color: ${t.colors.strokeElevate.toString()};
`;
