import React, { useEffect, useMemo } from "react";

import { useAuthContext } from "~src/shared/auth/AuthProvider";
import { useConnectAccounting } from "~src/shared/dataSources/accounting/hooks/useConnectAccounting";
import { useConnectBank } from "~src/shared/dataSources/bank/hooks/useConnectBank";
import { useConnectBilling } from "~src/shared/dataSources/billing/hooks/useConnectBilling";
import { useConnectDataSource } from "~src/shared/dataSources/connectDataSource/hooks/useConnectDataSource";
import { useOnDataSourceRedirectSuccessEffect } from "~src/shared/dataSources/useOnDataSourceRedirectSuccessEffect";
import { useFetchDataSourceRequirements } from "~src/shared/dataSourcesRequirements/hooks/useFetchDataSourceRequirements";
import { authedRequests } from "~src/shared/requests/authedRequests";
import { nextMutate } from "~src/shared/requests/hooks";
import { ApprovedPage } from "~src/vendor/inboxApproved/page";
import { ConnectDataPage } from "~src/vendor/inboxConnectData/page";
import { DeclinedPage } from "~src/vendor/inboxDeclined/page";
import { BeingReviewedPage } from "~src/vendor/inboxReviewed/page";

import { TradePage } from "../components/Trade";
import { useInboxState } from "../state";
import {
  computeInboxStateForVendor,
  IVendorInboxState,
  useClientAckedApproval,
} from "../utils/computeInboxState";

export const Inbox: React.FC = () => {
  const { vendor } = useAuthContext();
  const {
    data: requirements,
    loading,
    error,
    refetch: refetchDataSourceRequirements,
  } = useFetchDataSourceRequirements();

  const resetInboxState = useInboxState((s) => s.resetInboxState);
  const clientAckedApproval = useClientAckedApproval((s) => s.ackedApproval);

  // Reset the inbox state on unmount.
  useEffect(() => resetInboxState, [resetInboxState]);

  const vendorInboxState = useMemo(() => {
    if (vendor == null || requirements === undefined || loading) {
      return null;
    }
    return computeInboxStateForVendor(vendor, requirements, clientAckedApproval);
  }, [vendor, requirements, loading, clientAckedApproval]);

  const handleSuccess = React.useCallback(async (): Promise<void> => {
    await nextMutate(authedRequests.getVendor({}));
    await refetchDataSourceRequirements();
  }, [refetchDataSourceRequirements]);

  // Connect Data Source flow starter
  const openConnectDataSource = useConnectDataSource({
    onSuccess: handleSuccess,
    redirectPath: "/inbox",
    source: "trade-required-action-flow",
  });

  // Connect Plaid flow starter
  const { open: openPlaid } = useConnectBank({
    onSuccess: handleSuccess,
    redirectPath: "/inbox",
    source: "trade-required-action-flow",
  });

  // Connect Billing flow starter
  const { open: openBilling } = useConnectBilling({
    onSuccess: handleSuccess,
    redirectPath: "/inbox",
    source: "trade-required-action-flow",
  });

  // Connect Accounting flow starter
  const { open: openAccounting } = useConnectAccounting({
    onSuccess: handleSuccess,
    redirectPath: "/inbox",
    source: "trade-required-action-flow",
  });

  // Handle showing success screen for redirect-based integrations.
  useOnDataSourceRedirectSuccessEffect({
    currentRoute: "/inbox",
    openConnectDataSource,
  });

  // Switch to the right page based on the vendor state + requirements.
  if (vendorInboxState === IVendorInboxState.USER_ACTION_REQUIRED) {
    return (
      <ConnectDataPage
        requirements={requirements ?? []}
        openAccounting={openAccounting}
        openBank={openPlaid}
        openBilling={openBilling}
        reconnectOnSuccess={handleSuccess}
      />
    );
  }
  if (vendorInboxState === IVendorInboxState.IN_REVIEW) {
    return <BeingReviewedPage />;
  }
  if (vendorInboxState === IVendorInboxState.DECLINED) {
    return <DeclinedPage />;
  }
  if (vendorInboxState === IVendorInboxState.APPROVED) {
    return <ApprovedPage />;
  }
  if (vendorInboxState === IVendorInboxState.CAN_TRADE) {
    return <TradePage />;
  }

  if (loading || requirements == null) {
    return null;
  }
  // Toss the error to the app-level ErrorBoundary
  if (error) {
    throw error;
  }

  return (
    <ConnectDataPage
      requirements={requirements}
      openAccounting={openAccounting}
      openBank={openPlaid}
      openBilling={openBilling}
      reconnectOnSuccess={handleSuccess}
    />
  );
};
