import React from "react";

import { Card } from "~src/designSystem/atoms/Card";
import { Text } from "~src/designSystem/atoms/Text";
import { View } from "~src/designSystem/atoms/View";
import { Flex } from "~src/designSystem/layout/Flex";
import { Spacer } from "~src/designSystem/layout/Spacer";
import { ContactSupport } from "~src/shared/inbox/ContactSupport";
import { ICountryCode } from "~src/shared/types";

export type IProps = {
  country?: ICountryCode;
};

export const Declined: React.FC<IProps> = ({ country }) => {
  const recommendations = [
    {
      title: "Keep your data synced",
      body: "This allows us to keep reviewing your business as you grow, and notify you if there are any bids for your contracts.",
    },
    {
      title: "Improve the health of your business",
      body: "Businesses with over $50k ARR and balance, as well as low lien have a higher chance of receiving bids on their contracts.",
    },
  ];

  return (
    <View
      px="4"
      mt={{ "@initial": "20", "@lg": "6", "@md": "1" }}
      // TODO(gt): This is to override VendorLayout padding. This should be handled
      // at the layout level but we gotta do what we gotta do now.
      css={{
        "@media (max-width: 425px)": {
          "margin-left": "-48px",
          "margin-right": "-48px",
        },
      }}
    >
      <Flex
        direction={{ "@initial": "row", "@md": "column" }}
        justify="center"
        gap={{
          "@initial": "20",
          "@md": "10",
        }}
      >
        <Flex direction="column" maxWidth={{ "@initial": "96", "@md": "120" }}>
          <Text as="h1" color="contentEmphasis" text="titleSM">
            Review your results
          </Text>
          <Spacer y="6" />
          <View>
            <Text as="p">At this time, there are no active bids for your contracts.</Text>
            <Spacer y="2" />
            <Text as="p">
              We typically work with US & UK companies who have recurring revenue, with over $50k
              ARR and balance, and at least 6 months of runway. We'll monitor your account and let
              you know when there is demand for your contracts.
            </Text>
          </View>
          <Spacer y="6" />
          <ContactSupport country={country as ICountryCode} questionText="Need help?" />
        </Flex>
        <Flex direction="column" maxWidth="120">
          <Flex direction="column">
            <Text text="caption" color="contentSecondary">
              Recommendations
            </Text>
            <Spacer y="6" />
            <Flex align="center" gap="4" wrap>
              {recommendations.map((recommendation, idx) => (
                <Card key={`recommendation--${idx}`} gap="4px">
                  <Text>{recommendation.title}</Text>
                  <Text color="contentSecondary">{recommendation.body}</Text>
                </Card>
              ))}
            </Flex>
          </Flex>
        </Flex>
      </Flex>
    </View>
  );
};
