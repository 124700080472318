import {
  codat_platform_key_enum_enum,
  country_code_enum_enum,
  integration_type_enum_enum,
  legal_entity_type_enum_enum,
  uploaded_vendor_data_source_model_enum_enum,
  uploaded_vendor_data_source_status_enum_enum,
  uploaded_vendor_data_type_enum_enum,
  vendor_sales_funnel_stage_enum_enum,
} from "~src/__generated__/graphql/types";

export type IIntegrationType = keyof typeof integration_type_enum_enum;

export type IConnectDataSourceFlowSource =
  | "signup-flow"
  | "cash-performance-flow"
  | "suggestion-flow"
  | "settings-flow"
  | "trade-required-action-flow"
  | "trade-orderbox-add-payout-flow"
  | "notifications-badge"
  | "being-reviewed-add-more-data";

export interface INote {
  /**
   * Note text
   */
  raw: string;
  markdown?: string;
  html?: string;
  /**
   * List of public IDs of users to mention
   */
  mentionedUserIDs?: string[];
}

export interface IVendorPayment {
  publicID: string;
  paymentType: IPaymentType;
  dueAt: string | null;
  initiatedAt: string | null;
  clearedAt: string | null;
  internalPaymentID: string;
  paymentMedium: string;
  fee: number;
  notes: string;
  subscriptions: IVendorPaymentSyncedSubscription[];
  bankName: string;
}

export interface IVendorPaymentSyncedSubscription {
  customerName: string;
  customerEmail: string;
  internalPlanID: string;
  publicID: string;
  startsAt: string | null;
  lastPaymentDueAt: string | null;
  amount: number;
}

export type IVendor = Readonly<{
  publicID: string;
  clientID: string;
  name: string;
  logoURL: string;
  isActivated: boolean;
  isDeclined: boolean;
  isOnHold: boolean;
  canTrade: boolean;
  ackedApprovedForTradeAt: string | null;
}>;

export type ICountryCode = keyof typeof country_code_enum_enum;
export type ILegalEntityType = keyof typeof legal_entity_type_enum_enum;
export type IVendorSalesFunnelStageEnum = keyof typeof vendor_sales_funnel_stage_enum_enum;

export type ISearchQuery = {
  vendor?: {
    query?: string;
    publicID?: string;
  };
  list?: {
    query?: string;
    publicID?: string;
  };
};

export type ISearchResult =
  | {
      type: "vendor";
      data: {
        public_id: string;
        client_id: string;
        name: string;
        logo_url?: string;
      };
    }
  | {
      type: "list";
      data: {
        public_id: string;
        title: string;
        slug: string;
        creator_name: string;
      };
    };

// Codat platform keys must match the filenames in
// ~public/static/assets/external/accounting-platforms/${key}-black.svg.
export type ICodatPlatformKey = `${codat_platform_key_enum_enum}`;

export type IVendorStatus =
  | "ProviderLinkRequired"
  | "PlaidLinkRequired"
  | "AccountingLinkRequired"
  | "AwaitingActivation"
  | "Declined"
  | "Activated";

export type IUploadedVendorDataSourceModel = `${uploaded_vendor_data_source_model_enum_enum}`;
export type IUploadedVendorDataSourceStatus = `${uploaded_vendor_data_source_status_enum_enum}`;

export type IUploadedVendorDataType = `${uploaded_vendor_data_type_enum_enum}`;
export const IUploadedVendorDataTypeEnum = uploaded_vendor_data_type_enum_enum;

export type IPaymentType = "vendor_payment" | "vendor_payout";
