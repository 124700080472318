/* eslint-disable react/style-prop-object */
import React from "react";

import { t } from "~src/designSystem/theme";

export const NeedsAttentionIcon: React.FC = () => (
  <svg width="14" height="11" viewBox="0 0 14 11" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M6.99961 0.400024L13.3996 10.8H0.599609L6.99961 0.400024ZM6.1996 6.80002V3.60002H7.7996V6.80002H6.1996ZM6.1996 8.00002V9.60002H7.7996V8.00002H6.1996Z"
      fill={t.colors.statusWarningContent.toString()}
    />
  </svg>
);
