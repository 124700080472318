/* eslint-disable react/style-prop-object */
import React from "react";

import { t } from "~src/designSystem/theme";

export type IProps = {
  fill?: string;
};

export const LightningIcon: React.FC<IProps> = ({ fill }) => (
  <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M20.4001 9.60019H13.8001L15.0001 1.2002L3.6001 14.4002H10.2001L9.0001 22.8002L20.4001 9.60019Z"
      fill={fill ?? `${t.colors.strokeElevate}`}
    />
  </svg>
);
