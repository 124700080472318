import React from "react";

import { Link } from "~src/designSystem/atoms/Link";
import { Text } from "~src/designSystem/atoms/Text";
import {
  PIPE_CONTACT_URL,
  SUPPORT_EMAIL,
  SUPPORT_EMAIL_TEMPLATE_HREF,
} from "~src/shared/constants";
import { getSupportPhoneNumber } from "~src/shared/helpers/support";
import { ICountryCode } from "~src/shared/types";

export type IProps = {
  questionText?: string;
  country?: ICountryCode;
};

export const ContactSupport: React.FC<IProps> = ({ questionText = "Need help?", country }) => {
  const supportNumber = getSupportPhoneNumber(country);

  return (
    <>
      {questionText && (
        <Text as="p" color="contentSecondary">
          {questionText}
        </Text>
      )}
      <Text as="p" color="contentSecondary">
        Call us at <Link href={`tel:${supportNumber}`}>{supportNumber}</Link>, send us an email at{" "}
        <Link newTab href={SUPPORT_EMAIL_TEMPLATE_HREF}>
          {SUPPORT_EMAIL}
        </Link>
        , or{" "}
        <Link newTab href={PIPE_CONTACT_URL}>
          submit a support request.
        </Link>
      </Text>
    </>
  );
};
