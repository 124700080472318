import React, { useMemo } from "react";

import { permission_enum_enum } from "~src/__generated__/graphql/types";
import { Bank as BankLogo } from "~src/designSystem/icons/Bank";
import { configureCodatLinkURL } from "~src/shared/dataSources/accounting/codat";
import { IAllowedRedirectPath } from "~src/shared/dataSources/allowedRedirectPaths";
import { useReconnectBank } from "~src/shared/dataSources/bank/hooks/useReconnectBank";
import { useReconnectBilling } from "~src/shared/dataSources/billing/hooks/useReconnectBilling";
import { CSVManager } from "~src/shared/dataSources/manual/CSVManager";
import {
  IDataSource,
  IDataSource_Billing,
  IDataSource_Codat,
  IDataSource_Manual,
  IDataSource_Plaid,
} from "~src/shared/dataSources/useVendorDataSources";
import { useHasPermission } from "~src/shared/permissions";
import { useStepper } from "~src/shared/stepper/stepperContext";
import { IConnectDataSourceFlowSource } from "~src/shared/types";

import { DataSourceRow } from "./DataSourceRow";

type IProps = {
  dataSources: {
    accounting: IDataSource[];
    bank: IDataSource[];
    billing: IDataSource[];
  };
  numAdditionalDataSources: {
    accounting: number;
    bank: number;
    billing: number;
  };
  handleReconnect: () => Promise<void>;
  openBank: () => void;
  openAccounting: () => void;
  openBilling: () => void;
};

export const dataSourceRows = ({
  dataSources,
  numAdditionalDataSources,
  handleReconnect,
  openBank,
  openAccounting,
  openBilling,
}: IProps) => {
  return {
    accounting:
      dataSources.accounting.length > 0 || numAdditionalDataSources.accounting > 0 ? (
        <>
          {dataSources.accounting.map((source) => (
            <DataSourceRowRenderer
              key={source.publicID}
              dataSource={source}
              handleReconnect={handleReconnect}
            />
          ))}
          {[...Array(numAdditionalDataSources.accounting)].map((_, idx) => (
            <DataSourceRow
              key={`requested-accounting-${idx}`}
              type="requested"
              label="Accounting Source"
              actionOnClick={openAccounting}
            />
          ))}
        </>
      ) : undefined,
    bank:
      dataSources.bank.length > 0 || numAdditionalDataSources.bank > 0 ? (
        <>
          {dataSources.bank.map((source) => (
            <DataSourceRowRenderer
              key={source.publicID}
              dataSource={source}
              handleReconnect={handleReconnect}
            />
          ))}
          {[...Array(numAdditionalDataSources.bank)].map((_, idx) => (
            <DataSourceRow
              key={`requested-bank-${idx}`}
              type="requested"
              label="Bank Source"
              actionOnClick={openBank}
            />
          ))}
        </>
      ) : undefined,
    billing:
      dataSources.billing.length > 0 || numAdditionalDataSources.billing > 0 ? (
        <>
          {dataSources.billing.map((source) => (
            <DataSourceRowRenderer
              key={source.publicID}
              dataSource={source}
              handleReconnect={handleReconnect}
            />
          ))}
          {[...Array(numAdditionalDataSources.billing)].map((_, idx) => (
            <DataSourceRow
              key={`requested-revenue-${idx}`}
              type="requested"
              label="Revenue Source"
              actionOnClick={openBilling}
            />
          ))}
        </>
      ) : undefined,
  };
};

const DataSourceRowRenderer: React.FC<{
  dataSource: IDataSource;
  handleReconnect: () => Promise<void>;
}> = (props) => {
  const { dataSource, handleReconnect } = props;

  switch (dataSource.provider) {
    case "accounting_csv":
      return <DataSourceRow type="manual" label={dataSource.displayName} status="processed" />;
    case "plaid": {
      return <PlaidDataSourceRow dataSource={dataSource} handleReconnect={handleReconnect} />;
    }
    case "codat":
      return <CodatDataSourceRow dataSource={dataSource} />;
    case "manual":
      return <ManualDataSourceRow dataSource={dataSource} handleReconnect={handleReconnect} />;
    case "apple":
    case "chargebee":
    case "chargify":
    case "gocardless":
    case "paypal":
    case "recurly":
    case "stripe":
      return <BillingDataSourceRow dataSource={dataSource} handleReconnect={handleReconnect} />;
    default: {
      return null;
    }
  }
};

const PlaidDataSourceRow: React.FC<{
  dataSource: IDataSource_Plaid;
  handleReconnect: () => Promise<void>;
}> = (props) => {
  const { dataSource, handleReconnect } = props;
  const relinkRequired = dataSource.userActionRequiredAt != null;

  const options = useMemo(() => {
    return {
      onSuccess: handleReconnect,
      publicID: dataSource.plaidItemPublicID,
    };
  }, [dataSource.plaidItemPublicID, handleReconnect]);

  const { open: reconnectPlaid } = useReconnectBank(options);

  return (
    <DataSourceRow
      type="live"
      label={dataSource.displayName}
      icon={dataSource.logo !== "" ? dataSource.logo : undefined}
      fallbackIcon={<BankLogo />}
      status={relinkRequired ? "disconnected" : "live"}
      actionOnClick={reconnectPlaid}
    />
  );
};

const BillingDataSourceRow: React.FC<{
  dataSource: IDataSource_Billing;
  handleReconnect: () => Promise<void>;
}> = (props) => {
  const { dataSource, handleReconnect } = props;
  const relinkRequired = dataSource.userActionRequiredAt != null;

  const reconnectBilling = useReconnectBilling({
    onSuccess: async () => {
      await handleReconnect();
    },
    source: "trade-required-action-flow" as IConnectDataSourceFlowSource,
    subdomain: dataSource.subdomain,
    vendorNumber: dataSource.vendorNumber,
    redirectPath: "/inbox" as IAllowedRedirectPath,
    billingManager: {
      key: dataSource.provider,
      name: dataSource.displayName,
      subtitle: dataSource.displaySubheading ?? "",
      logo: dataSource.logo,
      type: "billing_manager",
    },
  });

  return (
    <DataSourceRow
      type="live"
      label={dataSource.displayName}
      icon={dataSource.logo}
      status={relinkRequired ? "disconnected" : "live"}
      actionOnClick={reconnectBilling}
    />
  );
};

const CodatDataSourceRow: React.FC<{
  dataSource: IDataSource_Codat;
}> = (props) => {
  const { dataSource } = props;
  const relinkRequired = dataSource.userActionRequiredAt != null;

  const reconnectCodat = () => {
    window.location.href = configureCodatLinkURL(
      dataSource.codatCompanyPublicID,
      dataSource.linkURL,
      "/inbox",
    );
  };
  return (
    <DataSourceRow
      type="live"
      label={dataSource.displayName}
      icon={dataSource.logo}
      status={relinkRequired ? "disconnected" : "live"}
      actionOnClick={reconnectCodat}
    />
  );
};

const ManualDataSourceRow: React.FC<{
  dataSource: IDataSource_Manual;
  handleReconnect: () => Promise<void>;
}> = (props) => {
  const { dataSource, handleReconnect } = props;
  const { addAndOpenStepperDialog } = useStepper();
  const relinkRequired = dataSource.userActionRequiredAt != null;

  const hasAddIntegrationpermission = useHasPermission(
    permission_enum_enum.resource__vendor_integrations__write,
  );

  const reconnectManualDataSource = () => {
    addAndOpenStepperDialog({
      component: (
        <CSVManager
          canAddIntegration={hasAddIntegrationpermission}
          type="billing_manager"
          dataSourceID={dataSource.publicID}
          source="trade-required-action-flow"
          refetchDataSources={handleReconnect}
        />
      ),
      config: {
        title: "Manage your revenue streams",
      },
    });
  };

  return (
    <DataSourceRow
      type="manual"
      label={dataSource.displayName}
      status={relinkRequired ? "stale" : "processed"}
      actionOnClick={reconnectManualDataSource}
    />
  );
};
