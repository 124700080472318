import styled from "@emotion/styled";
import { motion } from "framer-motion";
import React, { useMemo, useReducer } from "react";

import { PleaseGoUseFlexInstead } from "~src/designSystem/deprecated/Flexbox";

import { ChevronDown } from "../../icons/ChevronDown";

export type IAccordionProps = {
  id: string;
  /**
   * The first child is accordion header, which controls whether to show/hide the accordion panel/body's content
   * The second child is the accordion panel/body.
   */
  children: [React.ReactNode, React.ReactNode];
};

type AccordionActions = "show" | "hide";
type AccordionState = {
  expanded: boolean;
  header: {
    "aria-expanded": boolean;
    "aria-controls": string;
    "role": "button";
    "id": string;
  };
  panel: {
    "aria-hidden": boolean;
    "aria-labelledby": string;
    "id": string;
  };
};

function accordionReducer(state: AccordionState, action: AccordionActions) {
  switch (action) {
    case "show":
      return {
        expanded: true,
        header: {
          ...state.header,
          "aria-expanded": true,
        },
        panel: {
          ...state.panel,
          "aria-hidden": false,
        },
      };
    case "hide":
      return {
        expanded: false,
        header: {
          ...state.header,
          "aria-expanded": false,
        },
        panel: {
          ...state.panel,
          "aria-hidden": true,
        },
      };
  }
}

/**
 * Accordion component. Ideal for reducing a user's need to
 * scroll when rendering several sections/subsets of content on a single page.
 */
export const Accordion: React.FC<IAccordionProps> = (props) => {
  const { id, children } = props;
  const initialState: AccordionState = useMemo(
    () => ({
      expanded: false,
      header: {
        "aria-expanded": false,
        "aria-controls": `accordion_panel_${id}`,
        "role": "button",
        "id": `accordion_header-${id}`,
      },
      panel: {
        "aria-hidden": true,
        "aria-labelledby": `accordion_header_${id}`,
        "id": `accordion_panel_${id}`,
      },
    }),
    [id],
  );

  const [state, dispatch] = useReducer(accordionReducer, initialState);
  const [AccordionHeader, AccordionPanel] = children;

  return (
    <>
      <AccordionHeaderWrapper
        {...state.header}
        tabIndex={0}
        onKeyDown={(event) => {
          if (event.key === "Enter" || event.key === " ") {
            dispatch(state.expanded ? "hide" : "show");
          }
        }}
        onClick={() => dispatch(state.expanded ? "hide" : "show")}
      >
        {AccordionHeader}
        <PleaseGoUseFlexInstead marginLeft="auto" paddingRight="20px">
          <ChevronDown animate={{ rotate: state.expanded ? -180 : 0 }} />
        </PleaseGoUseFlexInstead>
      </AccordionHeaderWrapper>
      <motion.div
        initial={{ height: 0 }}
        animate={state.expanded ? { height: "auto" } : { height: 0 }}
        style={{ overflow: "hidden" }}
        {...state.panel}
      >
        {AccordionPanel}
      </motion.div>
    </>
  );
};

const AccordionHeaderWrapper = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  cursor: pointer;
  user-select: none;
`;
