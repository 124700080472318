import styled from "@emotion/styled";
import React, { useMemo } from "react";

import { Button } from "~src/designSystem/atoms/Button";
import { Card } from "~src/designSystem/atoms/Card";
import { Text } from "~src/designSystem/atoms/Text";
import { PleaseGoUseFlexInstead } from "~src/designSystem/deprecated/Flexbox";
import { Flex } from "~src/designSystem/layout/Flex";
import { t } from "~src/designSystem/theme";
import { useUser } from "~src/shared/auth/useUser";
import { useVendorDataSources } from "~src/shared/dataSources/useVendorDataSources";
import { useLegalEntityFormNewVendor } from "~src/vendor/legalEntities/hooks/useLegalEntityFormNewVendor";
import { useFetchLegalEntity } from "~src/vendor/settingsLegalEntities/useLegalEntity";

import { CircleCheck } from "./icons/CircleCheck";
import { CirclePlus } from "./icons/CirclePlus";

const CARD_NO_RADIUS_BREAKPOINT = "425px";

export const LegalEntityCard: React.FC = () => {
  const { vendor } = useUser();
  const { loading, requirement, refetch } = useFetchLegalEntity();
  const { data } = useVendorDataSources(vendor.id);

  const openLegalEntityForm = useLegalEntityFormNewVendor({ onSuccess: refetch });

  const hasLegalEntityRequirement = useMemo(
    () => !loading && requirement && requirement.entity == null,
    [loading, requirement],
  );

  /**
   * Do not render KYB Card if a vendor:
   *   - is not required to (e.g., UK vnedors)
   *   - does not have one or more data sources connected
   *   - the LegalEntityForm experiment is not enabled for the current vendor
   */
  if (loading || !requirement || !requirement.required || data.dataSources.length === 0) {
    return null;
  }

  const statusIcon = hasLegalEntityRequirement === true ? <CirclePlus /> : <CircleCheck />;

  return (
    <LegalEntityWrapper
      isActive={hasLegalEntityRequirement}
      row
      maxWidth="480px"
      fullWidth
      alignItems="flex-start"
    >
      <PleaseGoUseFlexInstead marginRight="32px" marginTop="0px" gap="6px">
        <Flex>
          <span style={{ marginRight: "4px", marginLeft: "-2px", marginTop: "2px" }}>
            {statusIcon}
          </span>
          <Text
            text={hasLegalEntityRequirement === true ? "paragraphStrong" : "paragraph"}
            color={hasLegalEntityRequirement === true ? "contentDefault" : "contentSecondary"}
          >
            Add your business details
          </Text>
        </Flex>
        <Text text="caption" color="contentSecondary" css={{ marginBottom: 0 }}>
          This allows us to verify your business.
        </Text>
      </PleaseGoUseFlexInstead>
      {hasLegalEntityRequirement === true && (
        <PleaseGoUseFlexInstead marginLeft="auto" shrink={0}>
          <Button size="small" kind="primary" onClick={openLegalEntityForm}>
            Add details
          </Button>
        </PleaseGoUseFlexInstead>
      )}
    </LegalEntityWrapper>
  );
};

const LegalEntityWrapper = styled(Card)`
  @media (max-width: ${CARD_NO_RADIUS_BREAKPOINT}) {
    border-radius: 0;
  }
  // TODO: remove if/when the Card component has new DS styles?
  background-color: ${t.colors.surfaceElevate.toString()};
  border-color: ${t.colors.strokeElevate.toString()};
`;
