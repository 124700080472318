import React from "react";

import { ErrorBoundary } from "~src/shared/errors/components/ErrorBoundary";
import { ErrorComponent } from "~src/shared/errors/components/ErrorComponent";
import { VendorLayout } from "~src/vendor/layout";

import { ProposalsProvider } from "../../contexts/ProposalsContext";
import { PayoutAccountsProvider } from "../OrderBox/PayoutMethod/PayoutAccounts";
import { TradeInner } from "./TradeInner";

export const TradePage: React.FC = () => {
  return (
    <ErrorBoundary fallback={<ErrorComponent />}>
      <VendorLayout>
        <ProposalsProvider>
          <PayoutAccountsProvider>
            <TradeInner />
          </PayoutAccountsProvider>
        </ProposalsProvider>
      </VendorLayout>
    </ErrorBoundary>
  );
};
