import styled from "@emotion/styled";
import React from "react";

import { PleaseGoUseFlexInstead } from "~src/designSystem/deprecated/Flexbox";
import { ReallyOldTextDontTouchAtAll } from "~src/designSystem/deprecated/Text";
import { CursorSelect } from "~src/designSystem/icons/CursorSelect";

type IProps = Record<string, never>;

export const EmptyState: React.FC<IProps> = () => {
  return (
    <PleaseGoUseFlexInstead alignItems="center" justifyContent="center" height="100px">
      <IconWrapper>
        <CursorSelect />
      </IconWrapper>
      <ReallyOldTextDontTouchAtAll weight="400" size="16px" color="#cacaca">
        No Contracts Selected
      </ReallyOldTextDontTouchAtAll>
    </PleaseGoUseFlexInstead>
  );
};

const IconWrapper = styled.div`
  width: 30px;
  height: 30px;
  margin-bottom: 20px;
`;
