import React from "react";

import { StatusTag } from "~src/designSystem/atoms/StatusTag";
import { IUploadedVendorDataSourceStatus } from "~src/shared/types";

interface IProps {
  status: IUploadedVendorDataSourceStatus;
}

export const ManualDataSourceStatusBadge = (props: IProps): React.ReactElement => {
  const { status } = props;
  switch (status) {
    case "succeeded":
      return <StatusTag color="green">Succeeded</StatusTag>;
    case "error":
      return <StatusTag color="red">Error</StatusTag>;
    case "processing":
      return <StatusTag color="purple">Processing</StatusTag>;
  }
};
