import * as Types from '../../../../../__generated__/graphql/types';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type ApprovedVendorMetricsQueryVariables = Types.Exact<{
  public_id: Types.InputMaybe<Types.Scalars['String']>;
}>;


export type ApprovedVendorMetricsQuery = { readonly __typename?: 'query_root', readonly vendors: ReadonlyArray<{ readonly __typename?: 'vendors', readonly public_id: string, readonly credit_limit: number, readonly rate_months_1: number, readonly max_contract_term_months: number, readonly vendor_metrics: ReadonlyArray<{ readonly __typename?: 'vendor_metrics', readonly total_addressable_arr: number | null }> }> };


export const ApprovedVendorMetricsQueryDocument = gql`
    query ApprovedVendorMetricsQuery($public_id: String) {
  vendors(where: {public_id: {_eq: $public_id}}) {
    public_id
    credit_limit
    rate_months_1
    max_contract_term_months
    vendor_metrics(order_by: {timestamp: desc}, limit: 1) {
      total_addressable_arr
    }
  }
}
    `;

/**
 * __useApprovedVendorMetricsQuery__
 *
 * To run a query within a React component, call `useApprovedVendorMetricsQuery` and pass it any options that fit your needs.
 * When your component renders, `useApprovedVendorMetricsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useApprovedVendorMetricsQuery({
 *   variables: {
 *      public_id: // value for 'public_id'
 *   },
 * });
 */
export function useApprovedVendorMetricsQuery(baseOptions?: Apollo.QueryHookOptions<ApprovedVendorMetricsQuery, ApprovedVendorMetricsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<ApprovedVendorMetricsQuery, ApprovedVendorMetricsQueryVariables>(ApprovedVendorMetricsQueryDocument, options);
      }
export function useApprovedVendorMetricsQueryLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<ApprovedVendorMetricsQuery, ApprovedVendorMetricsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<ApprovedVendorMetricsQuery, ApprovedVendorMetricsQueryVariables>(ApprovedVendorMetricsQueryDocument, options);
        }
export type ApprovedVendorMetricsQueryHookResult = ReturnType<typeof useApprovedVendorMetricsQuery>;
export type ApprovedVendorMetricsQueryLazyQueryHookResult = ReturnType<typeof useApprovedVendorMetricsQueryLazyQuery>;
export type ApprovedVendorMetricsQueryQueryResult = Apollo.QueryResult<ApprovedVendorMetricsQuery, ApprovedVendorMetricsQueryVariables>;