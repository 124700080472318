import { noop } from "lodash";
import { useEffect, useState } from "react";
import useScript from "react-script-hook";

import { ConsoleLog } from "~src/shared/helpers";
import { callRequest } from "~src/shared/requests/useRequest";
import { vendorRequests } from "~src/vendor/requests";

import { onErrorCommon } from "./helpers";
import { IPlaid, IPlaidLinkHandler } from "./types";

declare global {
  interface Window {
    Plaid?: IPlaid;
  }
}

export interface IUseReconnectBankOptions {
  publicID?: string;
  onSuccess?: () => void;
}

// The usePlaidLink hook in the official react-plaid-link SDK does not support
// asynchronous Link token creation. This hook is based on the official hook,
// but adds additional effect hooks to create and clear tokens. For a detailed
// explanation, see https://github.com/plaid/react-plaid-link/issues/128.
export const useReconnectBank = (
  options: IUseReconnectBankOptions,
): Pick<IPlaidLinkHandler, "open"> => {
  const { publicID, onSuccess } = options;
  const [token, setToken] = useState<string | undefined>();
  const [open, setOpen] = useState<IPlaidLinkHandler["open"]>(() => noop);

  // Load the Plaid Link initialization script asynchonously.
  const [loading, error] = useScript({
    src: "https://cdn.plaid.com/link/v2/stable/link-initialize.js",
    checkForExisting: true,
  });

  // Clear existing token whenever the public ID changes.
  useEffect(() => setToken(undefined), [publicID]);

  // Generate a new token whenever the existing token is cleared.
  useEffect(() => {
    if (token !== undefined || publicID === undefined) {
      return;
    }
    let mounted = true;
    (async () => {
      const res = await callRequest(vendorRequests.createPlaidLinkToken({ publicID }));
      // Why do we try to do it this way?
      // eslint-disable-next-line @typescript-eslint/no-unnecessary-condition
      if (mounted) {
        setToken(res.data?.token);
      }
    })();
    return () => {
      mounted = false;
    };
  }, [token, publicID]);

  // Initialize the Plaid handler once the Link token has been generated and
  // the Link initialization script has finished loading, and again whenever
  // the Link token is regenerated.
  useEffect(() => {
    if (
      token === undefined ||
      publicID === undefined ||
      loading ||
      error !== null ||
      window.Plaid === undefined
    ) {
      return;
    }
    let mounted = true;
    let loaded = false;
    const handler = window.Plaid.create({
      token,
      onLoad: () => {
        loaded = true;
      },
      onSuccess: async (_, metadata) => {
        ConsoleLog("Plaid success", metadata);
        await callRequest(vendorRequests.relinkPlaidItem({ publicID }));
        await onSuccess?.();
      },
      onEvent: (eventName, metadata) => ConsoleLog("Plaid event", eventName, metadata),
      onExit: async (onExitErr, metadata) => {
        ConsoleLog("Plaid exit metadata", metadata);
        if (onExitErr === null) {
          return;
        }
        ConsoleLog("Plaid exit error", error);
        if (onExitErr.error_code === "item-no-error") {
          // If the item does not require updating, the DB error must be stale.
          await callRequest(vendorRequests.relinkPlaidItem({ publicID }));
          options.onSuccess?.();
          return;
        }
        if (onExitErr.error_code === "INVALID_LINK_TOKEN") {
          // Generate a new token if the token has expired or the user has
          // attempted too many invalid logins.
          if (mounted) {
            setToken(undefined);
          }
          return;
        }
        onErrorCommon(onExitErr, metadata);
      },
    });
    setOpen(() => () => {
      if (loaded && mounted) {
        handler.open();
      }
    });
    return () => {
      mounted = false;
      handler.exit({ force: true }); // Close window.
      handler.destroy(); // Remove iframe.
    };
  }, [token, publicID, loading, error, onSuccess, options]);

  return { open };
};
