/** @jsxImportSource @emotion/react */
import { css } from "@emotion/react";
import styled from "@emotion/styled";
import React from "react";

import { PleaseGoUseFlexInstead } from "~src/designSystem/deprecated/Flexbox";
import { breakpoints } from "~src/shared/styles/breakpoints";

import { IInboxStep, useInboxState } from "../../state";
import { OrderBox } from "../OrderBox";
import { TradeReviewTable } from "./TradeReviewTable";
import { TradeSelectTable } from "./TradeSelectTable";

export const TradeSelectionArea: React.FC = () => {
  const wizardStep = useInboxState((state) => state.wizardStep);

  return (
    <>
      <PleaseGoUseFlexInstead
        gridColumn="span 2"
        css={css`
          position: relative;
          overflow-x: scroll !important;
        `}
      >
        <PleaseGoUseFlexInstead
          fullHeight
          css={css`
            min-width: fit-content;
          `}
        >
          <SubscriptionsTableWrapper className="inbox-table">
            {wizardStep === IInboxStep.INBOX && <TradeSelectTable />}
            {wizardStep >= IInboxStep.REVIEW && <TradeReviewTable />}
          </SubscriptionsTableWrapper>
        </PleaseGoUseFlexInstead>
      </PleaseGoUseFlexInstead>
      <PleaseGoUseFlexInstead
        gridColumn="span 1"
        css={css`
          ${breakpoints.desktop} {
            margin-top: 0px;
          }
          margin-top: calc(-41px - 24px);
        `}
      >
        <OrderBox />
      </PleaseGoUseFlexInstead>
    </>
  );
};

const SubscriptionsTableWrapper = styled(PleaseGoUseFlexInstead)`
  height: 100%;
  flex-grow: 2;
  position: relative;
`;
